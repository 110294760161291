import React, { ComponentType, useCallback, useContext, useEffect, useState } from 'react'
import {
	DataGridPremium,
	DataGridPremiumProps,
	GridCellParams,
	GridValidRowModel,
} from '@mui/x-data-grid-premium'
import Stack from '@mui/material/Stack'
import Paper from '@mui/material/Paper'
import { TableTypes } from '@cango-app/types'
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium'
import { Collapse } from '@mui/material'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import _isNumber from 'lodash/isNumber'

import { showSnackbar } from 'src/helpers/snackbarManager'

import { useColumnFormatter } from '../../modules/tables/use-column-formatter'
import { cangoTableAggregations, getRowDifference } from '../../modules/tables/core-table'
import { TableContext } from '../../providers/table-provider'
import { dataGridTables } from '../../helpers/ui'
import { CustomToolbar } from '../../modules/tables/toolbar'
import { Box } from '../box'
import { Text } from '../text'
import { IconButton } from '../icon-button'
import { CopyFullTableButton } from '../create-view/copy-full-table-button'

import { useRows } from './use-rows'
import { processUpdatedAggregatedRow } from './process-updated-aggregated-row'
import { getGroupId, getGroupingColumnId } from './get-group-id'
import { isSingleSelectColumn } from './is-single-select-column'

type DisplayViewProps = TableTypes.TableView & {
	initialState?: GridInitialStatePremium
	datagridProps?: Partial<DataGridPremiumProps>
}

export const DisplayView: ComponentType<DisplayViewProps> = (props) => {
	const {
		aggregations,
		subRowColumns: subRowColumnVisibility,
		columns: columnVisibility,
		displayChildren: shouldShowChildren,
		groupedField,
		initialState,
		datagridProps,
		displayMode,
	} = props
	const { cacheMultipleRowUpdates, apiRef, isUpdatingTable, saveChanges, mappedColumns } =
		useContext(TableContext)
	const { rows, subRows } = useRows(aggregations, shouldShowChildren, groupedField)
	const { columns } = useColumnFormatter({
		apiRef,
		isBulkEditEnabled: false,
		isTableLocked: true,
		showIcons: false,
		sortingModel: [],
	})

	const [visibleGroups, setVisibleGroups] = useState<string[]>(Object.keys(subRows))
	useEffect(() => {
		if (Object.keys(subRows).length) {
			setVisibleGroups(Object.keys(subRows))
		}
	}, [subRows])

	const processRowUpdate = useCallback(
		async (newRow: any, oldRow: any) => {
			const subs = subRows[newRow._id]
			const rowDifference = getRowDifference(oldRow, newRow)
			const subRowPayload = subs.map((subRow) => ({
				oldRow: subRow,
				newRow: {
					...subRow,
					...rowDifference.reduce((updatedFields: { [fieldId: string]: any }, fieldId) => {
						updatedFields[fieldId] = newRow[fieldId]
						return updatedFields
					}, {}),
				},
			}))
			const cachedRows = cacheMultipleRowUpdates(subRowPayload, true)
			await saveChanges()

			return processUpdatedAggregatedRow({
				groupId: getGroupId(groupedField, newRow[groupedField]),
				groupName: newRow[groupedField],
				subRows: cachedRows,
				aggregations,
				groupColumn: groupedField,
				mappedColumns,
			})
		},
		[subRows],
	)
	const handleCopy = useCallback(
		(groupedRows: GridValidRowModel[], columnId: string) => {
			const groupedColumn = columns.find(({ field }) => field === columnId)
			const rowValue = groupedRows[0][columnId]
			if (!groupedColumn) return
			const categoryLabel: string = isSingleSelectColumn(groupedColumn)
				? groupedColumn.valueOptions.find(({ _id }) => _id === groupedRows[0][columnId])?.label ??
					''
				: rowValue

			const copiedRows = Object.values(aggregations ?? {}).some(
				(aggregationFunction) => aggregationFunction,
			)
				? [...groupedRows, rows.find((row) => row[columnId] === rowValue) as GridValidRowModel]
				: groupedRows

			const filteredColumns = Object.keys(columnVisibility).length
				? columns.filter(({ field }) => columnVisibility[field])
				: columns

			const htmlTable = `
			<h2>${groupedColumn?.headerName}: ${categoryLabel}</h2>
      <table>
        <thead>
          <tr>
            ${filteredColumns
							.map((col) => {
								if (aggregations?.[col.field]) {
									return `<th>${col.headerName} (${aggregations?.[col.field]})</th>`
								}
								return `<th>${col.headerName}</th>`
							})
							.join('')}
          </tr>
        </thead>
        <tbody>
          ${copiedRows
						.map(
							(row) => `
            <tr>
              ${filteredColumns
								.map((col) => {
									const isSingleSelect = isSingleSelectColumn(col)
									if (isSingleSelect) {
										return `<td>${col.valueOptions.find(({ _id }) => _id === row[col.field])?.label ?? ''}</td>`
									}
									return `<td>${(_isNumber(row[col.field]) ? row[col.field].toFixed(2) : row[col.field]) ?? ''}</td>`
								})
								.join('')}
            </tr>
          `,
						)
						.join('')}
        </tbody>
      </table>
    `
			const blob = new Blob([htmlTable], { type: 'text/html' })
			const clipboardItem = new ClipboardItem({ 'text/html': blob })
			navigator.clipboard
				.write([clipboardItem])
				.then(() => {
					showSnackbar('Table copied!', { variant: 'success' })
				})
				.catch(() => {
					showSnackbar('Failed to copy table', { variant: 'error' })
				})
		},
		[columns, rows, columnVisibility],
	)

	const handleCellClick = async (params: GridCellParams) => {
		if (params.colDef.type === 'boolean') {
			if (isUpdatingTable) {
				return
			}
			const newValue = !params.value
			const cachedUpdate = await processRowUpdate(
				{ ...params.row, [params.field]: newValue },
				params.row,
			)
			if (cachedUpdate) {
				apiRef.current.updateRows([{ id: params.row._id, ...cachedUpdate }])
			}
			return
		}
	}
	const isEditableMode = displayMode === TableTypes.ViewStyleMode.editable
	if (!Object.keys(subRows).length) {
		return (
			<Stack direction="column" spacing={1}>
				<DataGridPremium
					apiRef={apiRef}
					initialState={initialState}
					columns={columns.map((_col) => ({
						..._col,
						editable: aggregations?.[_col.field] === 'value',
					}))}
					columnVisibilityModel={columnVisibility}
					aggregationModel={aggregations}
					rows={rows}
					getRowId={(row) => row._id}
					disableColumnMenu
					disableColumnFilter
					showColumnVerticalBorder={isEditableMode}
					showCellVerticalBorder={isEditableMode}
					processRowUpdate={processRowUpdate}
					aggregationFunctions={cangoTableAggregations}
					onCellClick={handleCellClick}
					sx={dataGridTables}
					getCellClassName={(params: GridCellParams) => {
						if (params.colDef.type === 'boolean') {
							return 'cell-checkbox'
						}
						return ''
					}}
					slots={{
						toolbar: CustomToolbar,
					}}
					slotProps={{
						toolbar: {
							hideDensitySelector: true,
							hideToolbar: true,
							isStatic: true,
						},
					}}
					{...datagridProps}
				/>
				<CopyFullTableButton {...props} />
			</Stack>
		)
	}
	if (isEditableMode) {
		return (
			<Stack direction="column" spacing={1}>
				<DataGridPremium
					apiRef={apiRef}
					initialState={initialState}
					columns={columns.map((_col) => ({
						..._col,
						editable: aggregations?.[_col.field] === 'value',
					}))}
					columnVisibilityModel={{
						...columnVisibility,
						__detail_panel_toggle__: shouldShowChildren && !!groupedField,
					}}
					rows={rows}
					getRowId={(row) => row._id}
					disableColumnMenu
					disableColumnFilter
					showColumnVerticalBorder
					showCellVerticalBorder
					processRowUpdate={processRowUpdate}
					onCellClick={handleCellClick}
					sx={dataGridTables}
					getCellClassName={(params: GridCellParams) => {
						if (params.colDef.type === 'boolean') {
							return 'cell-checkbox'
						}
						return ''
					}}
					slots={{
						toolbar: CustomToolbar,
					}}
					slotProps={{
						toolbar: {
							hideDensitySelector: true,
							hideToolbar: true,
							isStatic: true,
						},
					}}
					getDetailPanelContent={(row) => {
						const rowSubRows = subRows[row.id] ?? []

						if (!shouldShowChildren || !rowSubRows.length) {
							return null
						}

						return (
							<Stack sx={{ py: 2, height: '100%', boxSizing: 'border-box' }} direction="column">
								<Paper sx={{ flex: 1, mx: 'auto', width: '95%', p: 1 }}>
									<DataGridPremium
										disableColumnMenu
										disableColumnFilter
										getRowId={(row) => row._id}
										density="compact"
										columns={columns}
										columnVisibilityModel={{ ...subRowColumnVisibility, [groupedField]: false }}
										rows={rowSubRows}
										sx={{ flex: 1 }}
										hideFooter
										autosizeOnMount
										showColumnVerticalBorder
										showCellVerticalBorder
									/>
								</Paper>
							</Stack>
						)
					}}
					{...datagridProps}
				/>
			</Stack>
		)
	}

	return (
		<Stack direction="column" spacing={1} position="relative">
			<CopyFullTableButton {...props} />
			<Stack direction="column" spacing={2}>
				{Object.entries(subRows).map(([key, subRow]) => {
					const columnId = getGroupingColumnId(key)
					const column = columns.find(({ field }) => field === columnId)
					if (!column) return null
					const rowValue = subRow[0][columnId]
					const categoryLabel: string = isSingleSelectColumn(column)
						? column.valueOptions.find(({ _id }) => _id === subRow[0][columnId])?.label ?? ''
						: rowValue
					return (
						<Box key={key}>
							<Box display="flex" flexDirection="row" alignItems="center">
								<Text variant="h6">{`${column?.headerName ?? 'Column'}: ${categoryLabel}`}</Text>
								<IconButton
									aria-label={visibleGroups.includes(key) ? 'visibility' : 'visibility off'}
									onClick={() =>
										setVisibleGroups((currentGroups) => {
											if (currentGroups.includes(key)) {
												return currentGroups.filter((group) => group !== key)
											}
											return [...currentGroups, key]
										})
									}
								>
									{visibleGroups.includes(key) ? (
										<VisibilityOutlinedIcon />
									) : (
										<VisibilityOffOutlinedIcon />
									)}
								</IconButton>
								<IconButton
									aria-label="copy"
									onClick={() => handleCopy([...subRow], getGroupingColumnId(key))}
								>
									<ContentCopyOutlinedIcon />
								</IconButton>
							</Box>
							<Collapse in={visibleGroups.includes(key)}>
								<Paper>
									<DataGridPremium
										apiRef={apiRef}
										initialState={initialState}
										columns={columns}
										columnVisibilityModel={{ ...columnVisibility, [groupedField]: false }}
										aggregationModel={aggregations}
										rows={subRow}
										getRowId={(row) => row._id}
										disableColumnMenu
										disableColumnFilter
										aggregationFunctions={cangoTableAggregations}
										processRowUpdate={processRowUpdate}
										onCellClick={handleCellClick}
										sx={dataGridTables}
										getCellClassName={(params: GridCellParams) => {
											if (params.colDef.type === 'boolean') {
												return 'cell-checkbox'
											}
											return ''
										}}
										slots={{
											toolbar: CustomToolbar,
										}}
										slotProps={{
											toolbar: {
												hideDensitySelector: true,
												hideToolbar: true,
												isStatic: true,
											},
										}}
										{...datagridProps}
									/>
								</Paper>
							</Collapse>
						</Box>
					)
				})}
			</Stack>
		</Stack>
	)
}
