import React, { ComponentType, MutableRefObject, useContext, useMemo, useState } from 'react'
import {
	getGridDefaultColumnTypes,
	GridCellParams,
	GridColDef,
	GridColumnHeaderParams,
	GridFilterOperator,
	GridRenderCellParams,
	useGridApiContext,
} from '@mui/x-data-grid-premium'
import { TableTypes } from '@cango-app/types'
import classnames from 'classnames'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import LockIcon from '@mui/icons-material/Lock'
import FunctionsIcon from '@mui/icons-material/Functions'
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { GridSingleSelectColDef } from '@mui/x-data-grid/models/colDef/gridColDef'

import { selectors as contactSelectors } from '../../store/modules/contacts/selectors'
import { Box, Text, Select } from '../../components'
import { CellUrlLink } from '../my-tasks-v3/components/actions/cell-url-link'
import { VideoLink } from '../my-tasks-v3/components/actions/video-link'
import { FlagIcon, WarningIcon } from '../../assets/icons'
import { TableContext } from '../../providers/table-provider'

import { columnValueGetter } from './utils'
import { getFieldType } from './mui-formatter'

const renderTableHeader = ({
	params,
	field,
	showWarning,
	principalColumnId,
	showIcons,
}: {
	params: GridColumnHeaderParams
	field: TableTypes.Field
	showWarning: boolean
	principalColumnId?: string
	showIcons: boolean
}) => {
	return (
		<Box display="flex" alignItems="center">
			{showIcons && showWarning && <WarningIcon style={{ marginRight: '4px' }} />}
			{showIcons && field.locked && <LockIcon sx={{ marginRight: 1 }} />}
			{showIcons && field._id === principalColumnId && (
				<FlagIcon width={12} style={{ marginRight: 4 }} />
			)}
			{showIcons && field.type === TableTypes.FieldType.CALCULATION && (
				<FunctionsIcon sx={{ marginRight: 1 }} />
			)}
			<Text>
				<b>{params.colDef.headerName}</b>
			</Text>
		</Box>
	)
}

const SelectEditInputCell: ComponentType<
	GridRenderCellParams & {
		columnField: TableTypes.Field
	}
> = (props) => {
	const { id, value, field, columnField } = props
	const apiRef = useGridApiContext()
	const options = useMemo(() => {
		if (!columnField.valueOptions.length) return []
		if (!columnField.valueOptionFilters) return columnField.valueOptions
		let filteredValues: TableTypes.ValueOption[] = []
		for (const valueOptionFilter of columnField.valueOptionFilters) {
			const { filteredRowsLookup } = apiRef.current.getFilterState(valueOptionFilter.filters)
			if (filteredRowsLookup[id]) {
				filteredValues = [
					...filteredValues,
					...columnField.valueOptions.filter(({ _id }) => valueOptionFilter.options.includes(_id)),
				]
			}
		}
		return filteredValues.length > 0
			? filteredValues.filter(
					// delete duplicates from all matched filtered options
					(item, index, fullArray) => index === fullArray.findIndex((t) => t._id === item._id),
				)
			: columnField.valueOptions
	}, [columnField])

	const handleChange = async (newValue: string) => {
		await apiRef.current.setEditCellValue({ id, field, value: newValue })
		apiRef.current.stopCellEditMode({ id, field })
	}

	return (
		<Select
			options={options}
			value={value}
			defaultOpen
			onChange={(e) => handleChange(e.target.value as string)}
			fullWidth
			containerProps={{ width: '100%' }}
		/>
	)
}

const renderSelectEditInputCell = (params: GridRenderCellParams, field: TableTypes.Field) => {
	return <SelectEditInputCell {...params} columnField={field} />
}

const renderAutoCompleteInputCell = (
	params: GridRenderCellParams,
	valueOptions: TableTypes.Field['valueOptions'],
) => {
	const { id, value, field, row } = params
	const apiRef = useGridApiContext()

	const handleChange = async (value: string | null) => {
		await apiRef.current.setEditCellValue({ id, field, value })
		apiRef.current.stopRowEditMode({ id: row._id })
	}
	return (
		<Autocomplete
			freeSolo
			autoFocus
			value={value}
			onChange={(_e: any, newValue: TableTypes.ValueOption | null) => {
				handleChange(newValue?._id ?? null)
			}}
			options={valueOptions}
			fullWidth
			renderInput={(params) => <TextField {...params} fullWidth />}
		/>
	)
}

const wrapOperator = (operator: GridFilterOperator) => {
	const getApplyFilterFn: GridFilterOperator['getApplyFilterFn'] = (filterItem, column) => {
		const innerFilterFn = operator.getApplyFilterFn(filterItem, column)
		if (!innerFilterFn) {
			return innerFilterFn
		}

		return (value, row, col, apiRef) => {
			const valueOptions = (column as GridSingleSelectColDef)
				.valueOptions as TableTypes.ValueOption[]
			if (valueOptions && valueOptions.length) {
				if (operator.value === 'is') {
					return row[col.field] === filterItem.value
				} else if (operator.value === 'not') {
					return row[col.field] !== filterItem.value
				} else if (operator.value === 'isAnyOf') {
					return filterItem.value.includes(row[col.field])
				}
			}

			return innerFilterFn(value, row, col, apiRef)
		}
	}

	return {
		...operator,
		getApplyFilterFn,
	}
}

export const useColumnFormatter = ({
	isTableLocked,
	apiRef,
	isBulkEditEnabled,
	showIcons = true,
	sortingModel,
}: {
	isTableLocked: boolean
	apiRef: MutableRefObject<GridApiPremium>
	isBulkEditEnabled: boolean
	showIcons?: boolean
	sortingModel: TableTypes.CangoTable['sortingModel']
}) => {
	const { table } = useContext(TableContext)
	const tableFields = table?.fields ?? []
	const tableRecords = table?.records ?? []
	const principalField = table?.principal_field
	const mappedContacts = useSelector(contactSelectors.mappedContacts)
	const contactsForSelect = useSelector(contactSelectors.getContactsForSelect)
	const [defaultContentsId, setDefaultContentsId] = useState<string>()

	const columns = useMemo(() => {
		if (!tableFields.length) return []
		return tableFields.map((field): GridColDef => {
			const mappedValueOptions = new Map(
				field.valueOptions.map((_option) => [_option._id, _option]),
			)
			const columnType = getFieldType(field.type)
			const showWarning = tableRecords.some((_row) => {
				return (
					field.type === TableTypes.FieldType.CONTACT &&
					!mappedContacts.has(String(_row.data[field._id]))
				)
			})

			const defaultColumnTypes = getGridDefaultColumnTypes()
			const filterOperators = defaultColumnTypes[columnType].filterOperators

			return {
				field: field._id,
				headerName: field.name,
				maxWidth: 700,
				//@ts-ignore
				width: field.width,
				minWidth: 100,
				hideable: true,
				filterOperators: filterOperators?.map((operator) => wrapOperator(operator)),

				renderHeader: (params: GridColumnHeaderParams) =>
					renderTableHeader({
						params,
						field,
						showWarning,
						principalColumnId: principalField,
						showIcons,
					}),
				sortable: false,
				cellClassName: (params: GridCellParams) =>
					classnames({
						'cell--warning': showWarning && !mappedContacts.has(String(params.value)),
					}),
				...(field.type === TableTypes.FieldType.CONTACT && {
					valueOptions: contactsForSelect,
				}),
				type: columnType,
				...([
					TableTypes.FieldType.TABLE_SELECT,
					TableTypes.FieldType.SINGLE_SELECT,
					TableTypes.FieldType.REFERENCE,
				].includes(field.type) && {
					getOptionLabel: (option: TableTypes.ValueOption) => option.label,
					getOptionValue: (option: TableTypes.ValueOption) => option._id,
					valueOptions: field.valueOptions,
					renderEditCell: (params) => renderSelectEditInputCell(params, field),
				}),
				...([
					TableTypes.FieldType.TABLE_SELECT,
					TableTypes.FieldType.SINGLE_SELECT,
					TableTypes.FieldType.REFERENCE,
					TableTypes.FieldType.CONTACT,
				].includes(field.type)
					? {
							getOptionLabel: (option: TableTypes.ValueOption) => option.label,
							getOptionValue: (option: TableTypes.ValueOption) => option._id,
						}
					: {}),
				valueGetter: (value) => columnValueGetter(value, field, mappedValueOptions),
				valueFormatter: (value) => {
					if (field.type === TableTypes.FieldType.DATE) {
						return dayjs(value).format('MMM DD, YYYY')
					}

					if (field.format === TableTypes.FormatType.currency) {
						return value ? '$' + Number(value).toFixed(2) : null
					}

					if (field.format === TableTypes.FormatType.percentage) {
						return value ? `${value * 100} %` : null
					}

					if (field.type === TableTypes.FieldType.CONTACT) {
						const contact = mappedContacts.get(value)
						if (!contact) return value
						return `${contact?.name} ${contact?.surname}`
					}

					if (
						[
							TableTypes.FieldType.TABLE_SELECT,
							TableTypes.FieldType.SINGLE_SELECT,
							TableTypes.FieldType.REFERENCE,
						].includes(field.type)
					) {
						const _selectedOption = mappedValueOptions.get(value)
						if (!_selectedOption) {
							return value
						}
						return _selectedOption.label
					}

					return value
				},

				editable:
					!isTableLocked && !field.locked && field.type !== TableTypes.FieldType.CALCULATION,
				...(field.type === TableTypes.FieldType.RESOURCES && {
					renderCell: (cell: GridCellParams) => {
						return (
							<Text
								width="100%"
								fontSize={14}
								sx={{
									textDecoration: 'underline',
									cursor: 'pointer',
									textAlign: 'right',
								}}
								onClick={(e) => {
									e.stopPropagation()
									setDefaultContentsId(cell.id as string)
								}}
								p={1}
							>
								{cell.formattedValue as number}
							</Text>
						)
					},
				}),
				...(field.type === TableTypes.FieldType.URL && {
					renderCell: (cell: GridCellParams) => (
						<CellUrlLink
							cell={cell}
							onEditClick={() => {
								apiRef.current.startRowEditMode({ id: cell.row._id })
							}}
						/>
					),
				}),
				...(field.type === TableTypes.FieldType.VIDEO && {
					renderCell: (cell: GridCellParams) => (
						<VideoLink
							cell={cell}
							onEditClick={() => {
								apiRef.current.startRowEditMode({ id: cell.row._id })
							}}
						/>
					),
				}),
			}
		})
	}, [table, contactsForSelect, isBulkEditEnabled, sortingModel])

	return {
		columns,
		defaultContentsId,
		setDefaultContentsId,
	}
}
