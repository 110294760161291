import { V3BlueprintTypes, V3ClientTypes } from '@cango-app/types'
import _get from 'lodash/get'
import _orderBy from 'lodash/orderBy'

import { ProjectSection } from 'src/store/modules/projects-v3/selectors'

interface BaseTask {
	_id: string
	step?: {
		_id: string
	}
}

export const sortDocuments = <T extends BaseTask>(documents: T[], definedOrder: string[]): T[] => {
	if (!documents.length || !definedOrder.length) return documents
	const documentsCopy = [...documents]
	return documentsCopy.sort((a, b) => {
		if (!definedOrder) {
			definedOrder = []
		}
		let indexA = definedOrder.indexOf(a.step?._id ?? a._id)
		let indexB = definedOrder.indexOf(b.step?._id ?? b._id)

		if (indexA === -1) indexA = definedOrder.length
		if (indexB === -1) indexB = definedOrder.length

		return indexA - indexB
	})
}

type TaskWithSectionId = V3ClientTypes.Project.Task & { sectionId: string }

const nextEligibleTask = ({
	taskId,
	direction,
	checkOppositeDirection,
	sections,
}: {
	taskId: string
	direction: 1 | -1
	checkOppositeDirection?: boolean
	sections: ProjectSection[]
}): V3ClientTypes.Project.Task | null => {
	let currentSectionId: string | undefined = undefined
	const taskList = sections.reduce((_acc: TaskWithSectionId[], _section) => {
		return [
			..._acc,
			..._section.tasks.map((_task) => {
				if (_task._id === taskId) {
					currentSectionId = _section._id
				}
				return {
					..._task,
					sectionId: _section._id,
				}
			}),
		]
	}, [])
	const currentIndex = taskList.findIndex(({ _id }) => _id === taskId)

	let index = currentIndex

	if (index < 0) return null

	// Define a helper function to check if a task is eligible
	const isEligible = (task: TaskWithSectionId | undefined) => {
		if (!task || task.sectionId !== currentSectionId) return false
		return !(task.isMultiUse || task.lifecycle.complete)
	}

	// Check in the initial direction
	while (index >= 0 && index < taskList.length) {
		index += direction
		const task = _get(taskList, index)
		if (isEligible(task)) {
			return task
		}
	}

	// If no eligible task found in the initial direction and checkOppositeDirection is true
	if (checkOppositeDirection) {
		index = currentIndex
		while (index >= 0 && index < taskList.length) {
			index -= direction // Opposite direction
			const task = _get(taskList, index)
			if (isEligible(task)) {
				return task
			}
		}
	}

	// If still no eligible task found, return null
	return null
}

const nextEligibleSection = ({
	sectionId,
	direction,
	checkOppositeDirection,
	sections,
}: {
	sectionId: string
	direction: 1 | -1
	checkOppositeDirection?: boolean
	sections: ProjectSection[]
}): ProjectSection | null => {
	const currentIndex = sections.findIndex(({ _id }) => _id === sectionId)
	let index = currentIndex

	if (index < 0) return null

	// Define a helper function to check if a task is eligible
	const isEligible = (section: ProjectSection | undefined) => {
		return !!section
	}

	// Check in the initial direction
	while (index >= 0 && index < sections.length) {
		index += direction
		const section = _get(sections, index)
		if (isEligible(section)) {
			return section
		}
	}

	// If no eligible task found in the initial direction and checkOppositeDirection is true
	if (checkOppositeDirection) {
		index = currentIndex
		while (index >= 0 && index < sections.length) {
			index -= direction // Opposite direction
			const section = _get(sections, index)
			if (isEligible(section)) {
				return section
			}
		}
	}

	// If still no eligible task found, return null
	return null
}

const constructProjectSections = (projectTasks: V3ClientTypes.Project.Task[]) => {
	const chains = new Map<string, V3ClientTypes.Project.Task['chain']>()

	const sections = projectTasks.reduce(
		(_acc: { chainId?: string; sectionName: string; projectId: string }[], _task) => {
			if (_task.section === V3BlueprintTypes.PROJECT_WIDE_SECTION_NAME) return _acc

			if (_task.chain?._id && !chains.has(_task.chain._id)) {
				chains.set(_task.chain._id, _task.chain)
			}

			const taskChainId = _task.chain?._id

			if (!_acc.some((_section) => _section.sectionName === _task.section)) {
				_acc.push({
					chainId: taskChainId,
					sectionName: _task.section,
					projectId: _task.project_id,
				})
				return _acc
			}

			const correspondingChainSections = _acc.filter(({ chainId }) => chainId === taskChainId)

			if (!correspondingChainSections.length) {
				_acc.push({
					chainId: taskChainId,
					sectionName: _task.section,
					projectId: _task.project_id,
				})
				return _acc
			}

			const matchingSection = correspondingChainSections.find(
				({ sectionName }) => sectionName === _task.section,
			)

			if (!matchingSection) {
				_acc.push({
					chainId: taskChainId,
					sectionName: _task.section,
					projectId: _task.project_id,
				})
				return _acc
			}
			return _acc
		},
		[],
	)

	const mappedSections = sections.map((_section) => {
		return {
			..._section,
			chain: chains.get(_section.chainId ?? ''),
		}
	})

	return _orderBy(mappedSections, 'created_at', 'asc')
}

export const constructProjectSectionsWithTasks = (
	tasks: V3ClientTypes.Project.Task[],
	returnAll = false,
) => {
	const sections = constructProjectSections(tasks)
	const allTasks = Array.from(tasks.values())
	return sections.reduce((_acc: ProjectSection[], _section) => {
		const sectionTasks: V3ClientTypes.Project.Task[] = allTasks.filter((_task) => {
			if (_task.section !== _section.sectionName) return false
			return _section.chain?._id === _task.chain?._id
		})

		if (
			!returnAll &&
			(!sectionTasks.length ||
				sectionTasks.every((_task) => _task.isMultiUse || _task.lifecycle.complete))
		) {
			return _acc
		}
		return [
			..._acc,
			{
				_id: _section.chain?._id ?? _section.sectionName,
				name: _section.sectionName,
				tasks: _orderBy(sectionTasks, 'created_at', 'asc'),
				chain: _section.chain,
				isBlocked: sectionTasks.some(({ isFlagged }) => isFlagged),
				projectId: _section.projectId,
			},
		]
	}, [])
}

export const getNextEligibleTask = (currentTaskId: string, tasks: V3ClientTypes.Project.Task[]) => {
	const sections = constructProjectSectionsWithTasks(tasks)
	return nextEligibleTask({
		taskId: currentTaskId,
		direction: 1,
		checkOppositeDirection: true,
		sections,
	})
}

export const getNextEligibleSection = (
	currentSectionId: string,
	tasks: V3ClientTypes.Project.Task[],
) => {
	const sections = constructProjectSectionsWithTasks(tasks)
	return nextEligibleSection({
		sectionId: currentSectionId,
		direction: 1,
		checkOppositeDirection: true,
		sections,
	})
}
