import React, { ComponentType, memo, useContext, useMemo } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import FormControl from '@mui/material/FormControl'
import FormGroup from '@mui/material/FormGroup'
import Stack from '@mui/material/Stack'
import { TableTypes } from '@cango-app/types'
import { GridColumnVisibilityModel } from '@mui/x-data-grid-premium'

import { cangoTableAggregationList } from '../../modules/tables/core-table'
import { colors } from '../../theme/colors'
import { Text } from '../text'
import { Select } from '../select'
import { Box } from '../box'
import { TableContext } from '../../providers/table-provider'

const GroupingField: ComponentType<{
	columnList: { _id: string; label: string }[]
}> = ({ columnList }) => {
	const { control } = useFormContext<TableTypes.TableView>()
	return (
		<Controller
			control={control}
			name="groupedField"
			render={({ field: { value, onChange } }) => {
				return (
					<Select
						value={value}
						onChange={onChange}
						label="Group by column"
						fullWidth
						sx={{ mb: 2 }}
						options={columnList}
						withNoneOption
					/>
				)
			}}
		/>
	)
}

const ColumnItem: ComponentType<{ _column: { _id: string; label: string } }> = memo(
	function ColumnItem({ _column }) {
		const { control, watch } = useFormContext<TableTypes.TableView>()
		const groupedField: string | undefined = watch('groupedField')
		const columnVisibility = watch('columns')

		return (
			<Stack
				key={_column._id}
				direction="column"
				sx={{
					width: 175,
					mr: 1,
					mb: 1,
					border: `1px solid ${colors.neutral[40]}`,
					padding: 2,
					borderRadius: 2,
				}}
			>
				{!!groupedField && (
					<Controller
						key={_column._id}
						control={control}
						name={`aggregations.${_column._id}`}
						render={({ field: { value, onChange } }) => {
							return (
								<Select
									options={cangoTableAggregationList}
									label={_column.label}
									value={value}
									onChange={onChange}
									withNoneOption
									size="small"
									disabled={columnVisibility[_column._id] === false}
								/>
							)
						}}
					/>
				)}
			</Stack>
		)
	},
)

const ColumnList: ComponentType<{
	columnList: { _id: string; label: string }[]
}> = memo(function ColumnList({ columnList }) {
	const groupedField: string | undefined = useWatch<TableTypes.TableView>({ name: 'groupedField' })
	const columns: GridColumnVisibilityModel =
		useWatch<TableTypes.TableView>({ name: 'columns' }) ?? {}

	return (
		<FormGroup>
			<Box display="flex" flexWrap="wrap">
				{columnList.map((_column) => {
					if (_column._id === groupedField || columns[_column._id] === false) {
						return null
					}

					return <ColumnItem key={_column._id} _column={_column} />
				})}
			</Box>
		</FormGroup>
	)
})

export const Grouping: ComponentType = () => {
	const { mappedColumns } = useContext(TableContext)
	const groupedField: string | undefined = useWatch<TableTypes.TableView>({ name: 'groupedField' })

	const columnList = useMemo(() => {
		return [...mappedColumns.keys()].map((fieldId) => ({
			_id: fieldId,
			label: mappedColumns.get(fieldId)?.name ?? '',
		}))
	}, [mappedColumns])

	return (
		<Box>
			<GroupingField columnList={columnList} />
			<FormControl sx={{ mb: 2 }} component="fieldset" variant="standard">
				{!!groupedField && (
					<>
						<Text fontSize={14} color={colors.neutral['80']} fontWeight={500}>
							Aggregations
						</Text>
						<ColumnList columnList={columnList} />
					</>
				)}
			</FormControl>
		</Box>
	)
}
