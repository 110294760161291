import { ComponentType, useState } from 'react'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import Collapse from '@mui/material/Collapse'
import InputAdornment from '@mui/material/InputAdornment'
import ClearIcon from '@mui/icons-material/Clear'
import { V3ClientTypes } from '@cango-app/types'
import IconButton from '@mui/material/IconButton'

import { Box } from '../box'
import { Text } from '../text'
import { TextField } from '../text-field'
import { Select } from '../select'

export type Document = {
	_id: string
	name: string
	archived?: V3ClientTypes.Project.Project['archived']
	active?: boolean
}

type MenuFiltersProps = {
	onSortMethodChange: (id: string) => void
	selectedSortMethod: string
	onFilterByNameChange: (name: string) => void
	filterByName: string
}

export const MenuFilters: ComponentType<MenuFiltersProps> = ({
	onSortMethodChange,
	selectedSortMethod,
	onFilterByNameChange,
	filterByName,
}) => {
	const [isOpen, setIsOpen] = useState(false)

	return (
		<Box px={4} py={2}>
			<Box boxShadow="rgb(0 0 0 / 12%) 2px 4px 24px;" borderRadius="0 0 20px 20px" p={2}>
				<Box display="flex" alignItems="center" sx={{ cursor: 'pointer' }}>
					<Box flex={1} display="flex" alignItems="center" onClick={() => setIsOpen(!isOpen)}>
						<Text fontWeight="bold">
							Filters
							{isOpen ? <ExpandLess sx={{ ml: 1 }} /> : <ExpandMore sx={{ mr: 1 }} />}
						</Text>
					</Box>
					<Box display="flex" alignItems="center">
						<TextField
							label="Search"
							size="small"
							containerProps={{ mb: 0 }}
							value={filterByName}
							onChange={(event) => onFilterByNameChange(event.target.value)}
							InputProps={{
								sx: {
									px: 0,
								},
								endAdornment: (
									<InputAdornment position="end">
										<IconButton onClick={() => onFilterByNameChange('')}>
											<ClearIcon fontSize="small" />
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
					</Box>
				</Box>
				<Collapse in={isOpen} timeout="auto" unmountOnExit>
					<>
						<Box display="flex" justifyContent="space-between" mt={2}>
							<Box display="flex">
								<Select
									label="Sort by"
									fullWidth
									size="small"
									containerProps={{ width: 200 }}
									value={selectedSortMethod}
									onChange={(event) => onSortMethodChange(event.target.value as string)}
									options={[
										{ _id: '1', label: 'A-Z' },
										{ _id: '2', label: 'Z-A' },
									]}
								/>
							</Box>
						</Box>
					</>
				</Collapse>
			</Box>
		</Box>
	)
}
