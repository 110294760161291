import { TableTypes } from '@cango-app/types'

export const getDefaultValues = ({
	tableViews,
	viewId,
}: {
	tableViews: TableTypes.TableView[]
	viewId: string | undefined
}) => {
	const defaultVal = {
		aggregations: {},
		columns: {},
		subRowColumns: {},
		displayChildren: true,
		filters: {
			items: [],
		},
	}
	if (!tableViews.length || !viewId) {
		return defaultVal
	}

	const foundView = tableViews.find((_view) => _view._id === viewId)

	if (!foundView) {
		return defaultVal
	}

	return {
		aggregations: defaultVal.aggregations ?? {},
		columns: defaultVal.columns ?? {},
		subRowColumns: defaultVal.subRowColumns ?? {},
		displayChildren: defaultVal.displayChildren ?? true,
		filters: defaultVal.filters ?? {
			items: [],
		},
		groupedField: foundView.groupedField,
	}
}
