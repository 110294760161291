import { useContext, useMemo } from 'react'

import { TableContext } from '../../providers/table-provider'

import { getGroupId } from './get-group-id'

export const useGroupingIds = (groupingColumn: string | undefined) => {
	const { table } = useContext(TableContext)

	return useMemo((): Map<string, string> => {
		if (!table || !groupingColumn) {
			return new Map()
		}
		return table.records.reduce((_acc: Map<string, string>, _record) => {
			const row = _record.data
			if (row[groupingColumn] && !_acc.has(row[groupingColumn] as string)) {
				const columnName = row[groupingColumn] as string
				_acc.set(columnName, getGroupId(groupingColumn, columnName))
			}

			return _acc
		}, new Map())
	}, [table?.records, groupingColumn])
}
