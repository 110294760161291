import { GridAggregationModel, GridValidRowModel } from '@mui/x-data-grid-premium'
import { useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { TableContext } from '../../providers/table-provider'
import { selectors as contactSelectors } from '../../store/modules/contacts'
import { resolveAnyRowCalculations } from '../../modules/tables/utils'

import { useGroupingIds } from './use-grouping-ids'
import { processUpdatedAggregatedRow } from './process-updated-aggregated-row'

export const useRows = (
	aggregations: GridAggregationModel,
	showChildren: boolean,
	groupingColumn: string | undefined,
) => {
	const { table, mappedColumns } = useContext(TableContext)
	const mappedContacts = useSelector(contactSelectors.mappedContacts)
	const groupingIds = useGroupingIds(groupingColumn)
	const { rows, subRows } = useMemo((): {
		rows: GridValidRowModel[]
		subRows: { [groupId: string]: GridValidRowModel[] }
	} => {
		if (!table) {
			return {
				rows: [],
				subRows: {},
			}
		}

		if (!groupingIds.size || !groupingColumn) {
			return {
				rows: table.records.map((_record) =>
					resolveAnyRowCalculations({
						fields: table.fields,
						row: _record,
						contacts: mappedContacts,
					}),
				),
				subRows: {},
			}
		}

		const newSubRows = table.records.reduce(
			(
				_subRows: {
					[groupId: string]: GridValidRowModel[]
				},
				{ _id, data, references },
			) => {
				const groupId = groupingIds.get(data[groupingColumn] as string)
				if (!groupId) return _subRows
				const group = _subRows[groupId]
				const resolvedRow = resolveAnyRowCalculations({
					row: {
						_id,
						data,
						references,
					},
					fields: table.fields,
					contacts: mappedContacts,
				})
				if (group) {
					_subRows[groupId].push(resolvedRow)
				} else {
					_subRows[groupId] = [resolvedRow]
				}
				return _subRows
			},
			{},
		)

		const newRows = Array.from(groupingIds).map(([groupName, groupId]) =>
			processUpdatedAggregatedRow({
				groupId,
				groupName,
				subRows: newSubRows[groupId] ?? [],
				aggregations,
				groupColumn: groupingColumn,
				mappedColumns,
			}),
		)

		return {
			subRows: newSubRows,
			rows: newRows,
		}
	}, [groupingColumn, table, JSON.stringify(aggregations), groupingIds, showChildren])
	return {
		rows,
		subRows,
	}
}
