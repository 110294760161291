import { evaluateExpression } from '@cango-app/sdk'
import { TableTypes, ClientTypes } from '@cango-app/types'
import _isEmpty from 'lodash/isEmpty'
import isNaN from 'lodash/isNaN'
import isNull from 'lodash/isNull'
import _orderBy from 'lodash/orderBy'
import isUndefined from 'lodash/isUndefined'
import { GridFilterItem } from '@mui/x-data-grid-pro'
import {
	getGridNumericOperators,
	getGridSingleSelectOperators,
	getGridStringOperators,
	GridColumnVisibilityModel,
} from '@mui/x-data-grid-premium'

import { SingleSelectForm } from './column-settings/types'

export const performRowLevelCalculation = ({
	calculation,
	recordData,
	fieldValue,
	references,
}: {
	calculation: TableTypes.FormulaSlice[]
	recordData: TableTypes.Record['data']
	fieldValue: any
	references: Record<string, TableTypes.Record['data']>
}): number | undefined => {
	const stringCalculation = calculation
		.map(({ value, type, reference_column }) => {
			if (
				type === TableTypes.FormulaSliceType.REFERENCE_FIELD &&
				reference_column &&
				references &&
				!_isEmpty(references)
			) {
				const referenceData = references[reference_column]

				if (!referenceData) return undefined
				const recordDataValue = referenceData[value as string]
				if (!recordDataValue) return undefined
				return +recordDataValue
			}
			if (type === TableTypes.FormulaSliceType.FIELD) {
				const recordDataValue = recordData[value as string] ?? 0
				return +recordDataValue
			}
			if (type === TableTypes.FormulaSliceType.VLOOKUP) {
				return fieldValue
			}
			if (TableTypes.Operator[value as keyof typeof TableTypes.Operator]) {
				return TableTypes.Operator[value as keyof typeof TableTypes.Operator]
			}
			return value
		})
		.join('')
	const result = evaluateExpression(stringCalculation)
	return isNaN(result) ? 0 : result
}

const buildDependencyGraph = (fields: TableTypes.Field[]) => {
	const graph = new Map<string, Set<string>>()
	fields.forEach((field) => {
		if (field.type === 'calculation') {
			field.calculation.forEach((part) => {
				if (part.type === TableTypes.FormulaSliceType.FIELD) {
					if (!graph.has(field._id)) {
						graph.set(field._id, new Set())
					}
					graph.get(field._id)?.add(part.value)
				}
			})
		}
	})
	return graph
}

const topologicalSort = (fields: TableTypes.Field[], dependencyGraph: Map<string, Set<string>>) => {
	const visitedFields = new Map()
	const sortedFields: TableTypes.Field[] = []

	const visitField = (fieldId: string) => {
		if (visitedFields.get(fieldId)) {
			return
		}
		visitedFields.set(fieldId, true)

		const dependencies = dependencyGraph.get(fieldId)
		if (dependencies) {
			dependencies.forEach((dependentFieldId) => {
				visitField(dependentFieldId)
			})
		}

		const field = fields.find((field) => field._id === fieldId)
		if (field) {
			sortedFields.push(field)
		}
	}

	dependencyGraph.forEach((_, fieldId: string) => {
		visitField(fieldId)
	})

	fields.forEach((field) => {
		if (!visitedFields.get(field._id)) {
			sortedFields.push(field)
		}
	})

	return sortedFields
}

export type ResolvedRowData = { _id: string } & TableTypes.Record['data']

const replaceIdsWithValues = (
	input: string,
	row: TableTypes.Record,
	fields: TableTypes.Field[],
	contacts: Map<string, ClientTypes.Contact>,
) => {
	const { data: rowData, references } = row
	if (!input) return ''
	return input.replace(/{{([a-f0-9-]+(?:\.[a-z0-9-]+)*)}}/gi, (_match, id) => {
		const [baseId, nestedPath]: [string, string | undefined] = id.split('.')
		const foundField = fields.find(({ _id }) => baseId === _id)
		if (!foundField) return ''
		if (foundField.type === TableTypes.FieldType.SINGLE_SELECT) {
			const mappedValueOptions = new Map(
				foundField.valueOptions.map((_option) => [_option._id, _option]),
			)
			const selectedOption = mappedValueOptions.get(rowData[id] as string)?.label ?? ''
			return String(selectedOption)
		}

		if (foundField.type === TableTypes.FieldType.REFERENCE && nestedPath) {
			const referenceData = references[baseId]
			if (!referenceData) return ''
			const recordDataValue = referenceData[nestedPath]
			return String(recordDataValue)
		}

		if (foundField.type === TableTypes.FieldType.CALCULATION) {
			const resolvedCalculation =
				performRowLevelCalculation({
					calculation: foundField.calculation,
					recordData: rowData,
					fieldValue: rowData[foundField._id],
					references: references,
				}) ?? 0
			return String(resolvedCalculation)
		}

		if (foundField.type === TableTypes.FieldType.RESOURCES) {
			const resolvedResource = resolveResources(rowData[id] as TableTypes.Record['data'])
			return String(resolvedResource)
		}

		if (foundField.type === TableTypes.FieldType.CONTACT) {
			const foundContact = contacts.get(rowData[id] as string)
			return foundContact ? `${foundContact.name} ${foundContact.surname}` : ''
		}

		if (foundField.type === TableTypes.FieldType.BOOLEAN) {
			return isUndefined(rowData[id]) ? 'false' : String(rowData[id])
		}
		const value = isNull(rowData[id]) || isUndefined(rowData[id]) ? '' : rowData[id]
		return String(value)
	})
}

type ResolveAnyRowCalculations = {
	fields: TableTypes.Field[]
	row: TableTypes.Record
	contacts: Map<string, ClientTypes.Contact>
}

export const resolveAnyRowCalculations = ({
	row,
	fields,
	contacts,
}: ResolveAnyRowCalculations): ResolvedRowData => {
	const dependencyGraph = buildDependencyGraph(fields)
	const sortedFields = topologicalSort(fields, dependencyGraph)
	const rowData: TableTypes.Record['data'] = {}
	sortedFields.forEach((field) => {
		const rowKey = field._id
		if (field.type === TableTypes.FieldType.CALCULATION) {
			rowData[rowKey] = performRowLevelCalculation({
				calculation: field.calculation,
				recordData: rowData,
				fieldValue: row.data[field._id],
				references: row.references,
			})
		} else if (field.type === TableTypes.FieldType.CONCATINATION) {
			rowData[rowKey] = replaceIdsWithValues(field.concatination, row, fields, contacts)
		} else {
			rowData[rowKey] = row.data[rowKey]
		}
	})
	return { _id: row._id, ...rowData }
}

export const resolveResources = (resources: TableTypes.ResourceRecord) => {
	if (!resources?.data) return 0
	return resources.data.reduce((acc, record) => {
		const usage = record['usage'] || 1
		return acc + record['rate'] * record['quantity'] * usage
	}, 0)
}

export const getFilters = (table: TableTypes.CangoTable | undefined, filters: GridFilterItem[]) => {
	if (!table || !filters.length) return []
	const mappedColumnTypes = new Map(table.fields.map((_field) => [_field._id, _field.type]))

	return filters.map((_filter) => {
		const columnType = mappedColumnTypes.get(_filter.field)
		if (!columnType) return _filter
		const stringOperators = getGridStringOperators().map((_operator) => _operator.value)
		const singleSelectOperators = getGridSingleSelectOperators().map((_operator) => _operator.value)
		const numberOperators = getGridNumericOperators().map((_operator) => _operator.value)

		if (columnType === 'string' && !stringOperators.includes(_filter.operator)) {
			return {
				..._filter,
				operator: 'equals',
			}
		}

		if (columnType === 'calculation' && !numberOperators.includes(_filter.operator)) {
			return {
				..._filter,
				operator: '=',
			}
		}

		if (columnType === 'singleSelect' && !singleSelectOperators.includes(_filter.operator)) {
			return {
				..._filter,
				operator: 'is',
			}
		}

		return _filter
	})
}

export const getExplicitVisibilityModel = (
	visibilityModel: GridColumnVisibilityModel,
	columns: Map<string, TableTypes.Field>,
): GridColumnVisibilityModel => {
	return [...columns.keys()].reduce(
		(acc, key) => {
			// in case there a column which doesn't have a visibilityModel. Default = false

			return {
				...acc,
				[key]: !!visibilityModel[key],
			}
		},
		{
			__check__: false,
		},
	)
}

export const getCleanedUpSingleSelectData = (
	newData: SingleSelectForm,
	oldData: {
		options: TableTypes.Field['valueOptions']
		valueOptionFilters: TableTypes.Field['valueOptionFilters']
		single_select_lookup:
			| {
					tableId: string
					fieldId: string
			  }
			| undefined
	},
): {
	valueOptions: TableTypes.Field['valueOptions']
	single_select_lookup: TableTypes.Field['single_select_lookup']
	valueOptionFilters: TableTypes.Field['valueOptionFilters']
} => {
	// cases
	// old lookup new lookup
	// old lookup new custom -> reset single select filters
	// old custom new lookup
	// old custom new custom -> reset single select filters
	const orderedOptions = _orderBy(newData.options, 'label').filter(({ label }) => label)
	if (oldData.single_select_lookup?.tableId && newData.single_select_lookup?.tableId) {
		return {
			...newData,
			valueOptions: [],
		}
	}
	if (oldData.single_select_lookup?.tableId && !newData.single_select_lookup?.tableId) {
		return {
			valueOptions: orderedOptions,
			single_select_lookup: { tableId: '', fieldId: '' },
			valueOptionFilters: newData.valueOptionFilters.map(({ _id, label }) => ({
				_id,
				label,
				filters: { items: [] },
				options: [],
			})),
		}
	}
	if (!oldData.single_select_lookup?.tableId && newData.single_select_lookup?.tableId) {
		return {
			valueOptionFilters: newData.valueOptionFilters.map(({ _id, label }) => ({
				_id,
				label,
				filters: { items: [] },
				options: [],
			})),
			single_select_lookup: newData.single_select_lookup,
			valueOptions: [],
		}
	}
	if (!oldData.single_select_lookup?.tableId && !newData.single_select_lookup?.tableId) {
		return {
			...newData,
			valueOptions: orderedOptions,
			single_select_lookup: { tableId: '', fieldId: '' },
		}
	}
	return {
		...newData,
		valueOptions: newData.options,
	}
}

export const columnValueGetter = (
	value: any,
	field: TableTypes.Field,
	valueOptions: Map<string, { _id: string; label: string }>,
) => {
	if (field.type === TableTypes.FieldType.BOOLEAN) {
		return value === true || value === 'TRUE'
	}
	if (field.type === TableTypes.FieldType.RESOURCES) {
		return resolveResources(value)
	}

	if (
		[
			TableTypes.FieldType.TABLE_SELECT,
			TableTypes.FieldType.SINGLE_SELECT,
			TableTypes.FieldType.REFERENCE,
			TableTypes.FieldType.CONTACT,
		].includes(field.type)
	) {
		const _selectedOption = valueOptions.get(value)
		if (!_selectedOption) {
			return value
		}
		return _selectedOption.label
	}

	return value
}
