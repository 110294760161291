import { TableTypes } from '@cango-app/types'
import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'

export const DisplayToggle = () => {
	const { control } = useFormContext<TableTypes.TableView>()
	return (
		<Controller
			control={control}
			name="displayMode"
			render={({ field: { value, onChange } }) => (
				<ToggleButtonGroup value={value} onChange={onChange}>
					<ToggleButton value="editable">Editable</ToggleButton>
					<ToggleButton value="presentation">Presentation</ToggleButton>
				</ToggleButtonGroup>
			)}
		/>
	)
}
