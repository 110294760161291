import { createSelector } from '@reduxjs/toolkit'
import { ClientTypes, UserTypes } from '@cango-app/types'

import { getNameInitials } from 'src/routing/navigation/utils'

import { RootState } from '../../types'

const getUserState = (state: RootState) => state.cangoUser

const getUserDetails: (state: RootState) => ClientTypes.User | undefined = createSelector(
	getUserState,
	({ currentUser }) => currentUser,
)

const getCurrentUserId: (state: RootState) => string = createSelector(
	getUserDetails,
	(user) => user?._id || '',
)

const getAllUsers: (state: RootState) => ClientTypes.User[] = createSelector(
	getUserState,
	({ users }) => users,
)

const getMappedUsers: (state: RootState) => Map<string, ClientTypes.User> = createSelector(
	getAllUsers,
	(users) => new Map(users.map((user) => [user._id, user])),
)

const getUserById: (state: RootState, userId: string) => ClientTypes.User | undefined =
	createSelector(
		getAllUsers,
		(state: RootState, user_id: string) => user_id,
		(allUsers, userId) => allUsers.find(({ _id }) => _id === userId),
	)

const getAllUsersForSelect: (state: RootState) => Array<ClientTypes.User & { label: string }> =
	createSelector(getAllUsers, (users) =>
		users.map((user) => ({
			...user,
			label: `${user.name} ${user.surname}`,
			value: user._id,
		})),
	)

const getAllUsersForSelectWithUnassigned: (state: RootState) => { label: string; _id: string }[] =
	createSelector(getAllUsersForSelect, (users) => [
		...users,
		{ label: 'Unassigned', _id: 'unassigned' },
	])

const getAllUsersForTagging: (state: RootState) => Array<{ id: string; display: string }> =
	createSelector(getAllUsers, (users) =>
		users
			.map((user) => ({
				id: user._id,
				display: `${user.name} ${user.surname}`,
			}))
			.sort((a, b) => a.display.localeCompare(b.display)),
	)

const getPermissions: (state: RootState) => UserTypes.Permission | undefined = createSelector(
	getUserState,
	({ currentUser }) => currentUser?.permissions,
)

const getUserName: (state: RootState) => string | undefined = createSelector(
	getUserDetails,
	(user) => {
		if (!user?.name || !user?.surname) return undefined
		return `${user.name} ${user.surname}`
	},
)

const getUserEmail: (state: RootState) => string = createSelector(
	getUserDetails,
	(user) => user?.email || '',
)

const getUserInitials: (state: RootState) => string = createSelector(getUserDetails, (user) =>
	getNameInitials(user?.name, user?.surname),
)

const isLoadingUsers = createSelector(getUserState, ({ isLoadingUsers }) => isLoadingUsers)

export const selectors = {
	getMappedUsers,
	getUserDetails,
	getAllUsersForSelect,
	getAllUsersForTagging,
	getPermissions,
	getAllUsers,
	getUserById,
	getCurrentUserId,
	getAllUsersForSelectWithUnassigned,
	getUserName,
	getUserInitials,
	getUserEmail,
	isLoadingUsers,
}
