import React, { ComponentType } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { TableTypes } from '@cango-app/types'

import { Box } from '../box'
import { TextField } from '../text-field'

export const ViewSettings: ComponentType = () => {
	const { control } = useFormContext<TableTypes.TableView>()

	return (
		<Box>
			<Controller
				control={control}
				name="name"
				render={({ field: { value, onChange } }) => (
					<TextField value={value} onChange={onChange} label="View name" fullWidth sx={{ mb: 2 }} />
				)}
			/>
		</Box>
	)
}
