import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { useContext, useEffect } from 'react'

import { TableContext } from 'src/providers/table-provider'

import { CoreTable } from './core-table'
import { SingleSelectForm } from './column-settings/types'

type Props = {
	index: number
}

export const TableOptionsFilters = ({ index }: Props) => {
	const { control } = useFormContext<SingleSelectForm>()
	const { fields } = useFieldArray({ control, name: 'valueOptionFilters' })
	const { apiRef } = useContext(TableContext)

	useEffect(() => {
		if (!apiRef.current.setFilterModel) return
		apiRef.current.setFilterModel(fields[index].filters)
	}, [apiRef, fields, index])

	return (
		<Controller
			control={control}
			name={`valueOptionFilters.${index}.filters`}
			render={({ field: { onChange } }) => (
				<CoreTable
					hideFooter
					hideToolbar
					hideColumnSelector
					hideDensitySelector
					isStatic
					onFilterChange={onChange}
					styles={{
						'& .MuiDataGrid-main': {
							display: 'none',
						},
						'& .MuiDataGrid-root': {
							border: 'none !important',
						},
						'& .MuiDataGrid-toolbarContainer': {
							padding: 1,
						},
					}}
				/>
			)}
		/>
	)
}
