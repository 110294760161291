import React, { ComponentType, useContext } from 'react'
import { TableTypes } from '@cango-app/types'

import { TableContext } from '../../../providers/table-provider'
import { Box, Modal } from '../../../components'

import { CalculationModal } from './calculation-modal/calculation-modal'
import { ConcatinationModal } from './concatination-modal'
import { columnsWithSettings } from './types'
import { SingleSelectSettings } from './single-select-settings'

type ColumnSettingsModalProps = {
	columnId: string | null
	onClose: () => void
}

const UnDecoratedColumnSettingsModal: ComponentType<ColumnSettingsModalProps> = ({
	columnId,
	onClose,
}) => {
	const { mappedColumns } = useContext(TableContext)
	const column = mappedColumns.get(columnId ?? '')

	if (!columnId || !column || !columnsWithSettings.includes(column.type)) return null
	return (
		<Modal
			open={!!column}
			onClose={onClose}
			onKeyDown={(e) => e.stopPropagation()}
			onScroll={(e) => e.stopPropagation()}
			containerStyle={{
				maxWidth: 'lg',
				overflowX: 'hidden',
				overflowY: 'auto',
			}}
		>
			<Box
				minWidth={500}
				minHeight={column.type === TableTypes.FieldType.CALCULATION ? 400 : 300}
				width={column.type === TableTypes.FieldType.CALCULATION ? '100%' : undefined}
			>
				{column.type === TableTypes.FieldType.SINGLE_SELECT && (
					<SingleSelectSettings
						defaultOptions={column.valueOptions}
						valueOptionFilters={column?.valueOptionFilters ?? []}
						columnId={column._id}
						onClose={onClose}
						defaultSingleSelectLookup={column.single_select_lookup}
					/>
				)}
				{column.type === TableTypes.FieldType.CALCULATION && (
					<CalculationModal
						defaultCalculation={column.calculation}
						columnId={column._id}
						onClose={onClose}
					/>
				)}
				{column.type === TableTypes.FieldType.CONCATINATION && (
					<ConcatinationModal columnId={column._id} />
				)}
			</Box>
		</Modal>
	)
}

export const ColumnSettingsModal = React.memo(UnDecoratedColumnSettingsModal)
