import { TableTypes } from '@cango-app/types'
import { ComponentType, useCallback, useContext } from 'react'
import { GridValidRowModel } from '@mui/x-data-grid-premium'
import _isEmpty from 'lodash/isEmpty'
import _isNumber from 'lodash/isNumber'

import { showSnackbar } from 'src/helpers/snackbarManager'
import { useColumnFormatter } from 'src/modules/tables/use-column-formatter'
import { TableContext } from 'src/providers/table-provider'

import { Button } from '../button'
import { useRows } from '../display-view/use-rows'
import { getGroupingColumnId } from '../display-view/get-group-id'
import { isSingleSelectColumn } from '../display-view/is-single-select-column'
import { Box } from '../box'

export const CopyFullTableButton: ComponentType<TableTypes.TableView> = ({
	aggregations,
	displayChildren,
	groupedField,
	displayMode,
	columns: columnVisibility,
}) => {
	const { apiRef, table } = useContext(TableContext)
	const { rows, subRows } = useRows(aggregations, displayChildren, groupedField)
	const { columns } = useColumnFormatter({
		apiRef,
		isBulkEditEnabled: false,
		isTableLocked: true,
		showIcons: false,
		sortingModel: [],
	})

	const handleFullCopy = useCallback(() => {
		const filteredColumns = Object.keys(columnVisibility).length
			? columns.filter(({ field }) => columnVisibility[field])
			: columns

		const htmlFullTable = _isEmpty(subRows)
			? `<h2>${table?.name}</h2>
      <table>
        <thead>
          <tr>
            ${filteredColumns
							.map((col) => {
								if (aggregations[col.field]) {
									return `<th>${col.headerName} <strong>(${aggregations[col.field]})</strong></th>`
								}
								return `<th>${col.headerName}</th>`
							})
							.join('')}
          </tr>
        </thead>
        <tbody>
          ${rows
						.map(
							(row) => `
            <tr>
              ${filteredColumns
								.map((col) => {
									const isSingleSelect = isSingleSelectColumn(col)
									if (isSingleSelect) {
										return `<td>${col.valueOptions.find(({ _id }) => _id === row[col.field])?.label ?? ''}</td>`
									}
									return `<td>${(_isNumber(row[col.field]) ? row[col.field].toFixed(2) : row[col.field]) ?? ''}</td>`
								})
								.join('')}
            </tr>
          `,
						)
						.join('')}
        </tbody>
      </table>
      <br/>
      <br/>`
			: Object.entries(subRows)
					.map(([key, groupedRows]) => {
						const columnId = getGroupingColumnId(key)
						const groupedColumn = columns.find(({ field }) => field === columnId)
						const rowValue = groupedRows[0][columnId]
						if (!groupedColumn) return
						const categoryLabel: string = isSingleSelectColumn(groupedColumn)
							? groupedColumn.valueOptions.find(({ _id }) => _id === groupedRows[0][columnId])
									?.label ?? ''
							: rowValue

						const copiedRows = Object.values(aggregations ?? {}).some(
							(aggregationFunction) => aggregationFunction,
						)
							? [
									...groupedRows,
									rows.find((row) => row[columnId] === rowValue) as GridValidRowModel,
								]
							: groupedRows

						return `
			<h2>${groupedColumn?.headerName}: ${categoryLabel}</h2>
      <table>
        <thead>
          <tr>
            ${filteredColumns
							.map((col) => {
								if (aggregations?.[col.field]) {
									return `<th>${col.headerName} <strong>(${aggregations?.[col.field]})</strong></th>`
								}
								return `<th>${col.headerName}</th>`
							})
							.join('')}
          </tr>
        </thead>
        <tbody>
          ${copiedRows
						.map(
							(row) => `
            <tr>
              ${filteredColumns
								.map((col) => {
									const isSingleSelect = isSingleSelectColumn(col)
									if (isSingleSelect) {
										return `<td>${col.valueOptions.find(({ _id }) => _id === row[col.field])?.label ?? ''}</td>`
									}
									return `<td>${(_isNumber(row[col.field]) ? row[col.field].toFixed(2) : row[col.field]) ?? ''}</td>`
								})
								.join('')}
            </tr>
          `,
						)
						.join('')}
        </tbody>
      </table>
      <br/>
      <br/>
    `
					})
					.join('')

		const blob = new Blob([htmlFullTable], { type: 'text/html' })
		const clipboardItem = new ClipboardItem({ 'text/html': blob })
		navigator.clipboard
			.write([clipboardItem])
			.then(() => {
				showSnackbar('Table copied!', { variant: 'success' })
			})
			.catch(() => {
				showSnackbar('Failed to copy table', { variant: 'error' })
			})
	}, [columns, rows, subRows, aggregations, columnVisibility])

	const isPresentation = displayMode === TableTypes.ViewStyleMode.presentation
	return isPresentation ? (
		<Box width="100%" position="sticky" top={0} zIndex={9999} pb={2} bgcolor="white">
			<Button fullWidth variant="outlined" onClick={handleFullCopy} sx={{ mt: 2 }}>
				Copy all
			</Button>
		</Box>
	) : null
}
