import { ComponentType, useCallback, useContext, useMemo, useState } from 'react'
import { TableTypes } from '@cango-app/types'
import { isEvaluable } from '@cango-app/sdk'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { v4 } from 'uuid'
import { Stack } from '@mui/material'

import { TableContext } from '../../../../providers/table-provider'
import { showSnackbar } from '../../../../helpers/snackbarManager'
import { Box, Text, Button, Grid } from '../../../../components'
import { SaveIcon } from '../../../../assets/icons'
import { colors } from '../../../../theme/colors'

import { ConfigureCalculationForm } from './types'
import { allOperators, formulas } from './utils'
import { Slice } from './slice'
import { TableVLookup } from './table-vlookup'
import { CreateFormula } from './create-formula'
import { MathematicalOperators } from './mathematical-operators'
import { FormulaOperators } from './formula-operators'
import { ColumnList } from './column-list'
import { ReferenceColumnList } from './reference-column-list'
import { NumberInput } from './number-input'

type CalculationModalProps = {
	defaultCalculation: TableTypes.FormulaSlice[]
	columnId: string
	onClose: () => void
}

const getDefaultCalculation = (defaultCalculation: TableTypes.FormulaSlice[]) => {
	return defaultCalculation.map((_calc, index) => {
		const indexOfId = defaultCalculation.findIndex((_indexCalc) => _indexCalc.id === _calc.id)
		if (indexOfId !== index) {
			_calc.id = v4()
		}
		return _calc
	})
}

export const CalculationModal: ComponentType<CalculationModalProps> = ({
	columnId,
	defaultCalculation,
	onClose,
}) => {
	const formValues = useForm<ConfigureCalculationForm>({
		defaultValues: {
			calculation: getDefaultCalculation(defaultCalculation),
		},
	})
	const {
		control,
		handleSubmit,
		formState: { isDirty },
		watch,
	} = formValues
	const { fields, append, remove, update } = useFieldArray({ control, name: 'calculation' })
	const calculations = watch('calculation')
	const { onUpdateColumn, isUpdatingTable, mappedColumns, table, tableList } =
		useContext(TableContext)
	const [activeOperatorId, setActiveOperatorId] = useState('')
	const [selectedFieldId, setSelectedFieldId] = useState('')

	const resetActiveIds = useCallback(() => {
		setActiveOperatorId('')
		setSelectedFieldId('')
	}, [])

	const handleSaveCalculation = async (data: ConfigureCalculationForm) => {
		if (!isEvaluable(partialCalculation)) {
			showSnackbar('Invalid calculation', { variant: 'error' })
			return
		}

		const response = await onUpdateColumn(columnId, { calculation: data.calculation })
		if (response.result === 'success') {
			onClose()
		}
	}

	const selectedField = useMemo(() => {
		return fields.find((_calc) => _calc.id === selectedFieldId)
	}, [selectedFieldId, fields])

	const getLabel = useCallback(
		({ type, value, lookup, reference_column }: TableTypes.FormulaSlice) => {
			switch (type) {
				case TableTypes.FormulaSliceType.FIELD:
					return mappedColumns.get(value)?.name
				case TableTypes.FormulaSliceType.OPERATOR:
					return allOperators.find(({ _id }) => _id === value)?.label
				case TableTypes.FormulaSliceType.NUMBER:
					return value
				case TableTypes.FormulaSliceType.LOOKUP:
					return `Lookup from ${tableList.find(({ _id }) => _id === lookup?.tableId)?.label ?? 'TBC'}`
				case TableTypes.FormulaSliceType.VLOOKUP:
					return `VLookup`
				case TableTypes.FormulaSliceType.REFERENCE_FIELD: {
					if (!reference_column) {
						return ''
					}
					const mainColumn = mappedColumns.get(reference_column)
					if (!mainColumn) {
						return ''
					}
					const referencedColumn = table?.referenceColumnNames[reference_column].find(
						(_refCol) => _refCol._id === value,
					)?.label
					return `${mainColumn.name}.${referencedColumn}`
				}
				default:
					return ''
			}
		},
		[mappedColumns, tableList, table?.referenceColumnNames],
	)

	const addField = useCallback(
		(newSlice: TableTypes.FormulaSlice) => {
			const existingFieldIndex = fields.findIndex((_field) => _field.id === newSlice.id)
			if (existingFieldIndex > -1) {
				update(existingFieldIndex, newSlice)
			} else {
				append(newSlice)
			}
			resetActiveIds()
		},
		[fields],
	)

	const partialCalculation = useMemo(() => {
		return fields
			.map(({ value, type }) => {
				if (type === TableTypes.FormulaSliceType.FIELD) {
					return 1 // convert fields to any number for evaluating formula
				}
				if (
					type === TableTypes.FormulaSliceType.LOOKUP ||
					type === TableTypes.FormulaSliceType.VLOOKUP ||
					type === TableTypes.FormulaSliceType.REFERENCE_FIELD
				) {
					return 1 // convert lookup to any number for evaluating formula
				}
				if (TableTypes.Operator[value as keyof typeof TableTypes.Operator]) {
					return TableTypes.Operator[value as keyof typeof TableTypes.Operator]
				}
				return value
			})
			.join('')
	}, [fields])

	return (
		<FormProvider {...formValues}>
			<Box>
				<Grid container columnSpacing={2}>
					<Grid item xs={3} />
					<Grid item xs={6} display="flex" alignItems="center" justifyContent="center">
						<Text fontWeight="bold" variant="overline" textAlign="center" mt={2}>
							Configure Calculation
						</Text>
					</Grid>
					<Grid item xs={3}>
						<Box display="flex" justifyContent="flex-end">
							<Button
								startIcon={<SaveIcon fill="#fff" />}
								size="small"
								onClick={handleSubmit(handleSaveCalculation)}
								isLoading={isUpdatingTable}
								disabled={!isDirty}
								sx={{ mt: 2, minWidth: 100 }}
							>
								Save
							</Button>
						</Box>
					</Grid>
					<Grid item xs={12} mb={2}>
						<MathematicalOperators onOperatorClick={addField} />
					</Grid>
					<Grid item xs={12} mb={2}>
						<FormulaOperators
							activeOperatorId={activeOperatorId}
							onFormulaClick={(_operatorId) => {
								if (_operatorId) {
									setActiveOperatorId(_operatorId)
									return
								}
								resetActiveIds()
							}}
						/>
					</Grid>
					{!!activeOperatorId && formulas.includes(activeOperatorId) && (
						<Grid item xs={12}>
							<CreateFormula activeOperator={activeOperatorId} onAddField={addField} />
						</Grid>
					)}
					{activeOperatorId === TableTypes.FormulaSliceType.VLOOKUP && (
						<Grid item xs={12}>
							<TableVLookup
								defaultValues={selectedField?.vlookup}
								onSaveVLookup={addField}
								selectedId={selectedFieldId}
							/>
						</Grid>
					)}
					{!activeOperatorId && (
						<>
							<Grid item xs={12} mb={2}>
								<ColumnList onItemClick={addField} />
							</Grid>
							<ReferenceColumnList onFieldClick={addField} />
							<NumberInput onAddNumber={addField} />
						</>
					)}
					<Grid item xs={12} marginTop={3}>
						<Stack direction="row" alignItems="center">
							<Text flex={1}>Calculation:</Text>
							<Button
								size="small"
								variant="text"
								onClick={() => {
									remove()
									resetActiveIds()
								}}
							>
								Clear all
							</Button>
						</Stack>
					</Grid>
					<Grid item xs={12}>
						<Stack
							direction="row"
							marginY={1}
							paddingY={2}
							sx={{
								overflowX: 'auto',
							}}
						>
							{fields.map((calc, index) => (
								<Slice
									control={control}
									onClick={() => {
										if (calc.type === TableTypes.FormulaSliceType.VLOOKUP) {
											setActiveOperatorId(TableTypes.FormulaSliceType.VLOOKUP)
											setSelectedFieldId(calc.id)
										}
									}}
									onDelete={() => {
										remove(index)
										resetActiveIds()
									}}
									sliceIndex={index}
									key={calc.id}
								/>
							))}
						</Stack>
					</Grid>
					<Grid item xs={12} display="flex" justifyContent="center">
						<Text color={isEvaluable(partialCalculation) ? 'inherit' : colors['error']['main']}>
							{calculations.map((calc) => getLabel(calc)).join('')}
						</Text>
					</Grid>
				</Grid>
			</Box>
		</FormProvider>
	)
}
