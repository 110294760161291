import { ComponentType, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { TableTypes } from '@cango-app/types'
import { useSelector } from 'react-redux'
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { v4 } from 'uuid'
import Papa, { ParseResult } from 'papaparse'
import _uniq from 'lodash/uniq'
import { TablesSdk } from '@cango-app/sdk'
import { Grid, Stack } from '@mui/material'

import { selectors as authSelectors } from '../../../store/modules/auth'
import { TableContext } from '../../../providers/table-provider'
import { getCleanedUpSingleSelectData } from '../utils'
import { showSnackbar } from '../../../helpers/snackbarManager'
import {
	Box,
	Button,
	Divider,
	IconButton,
	Select,
	Text,
	TextField,
	Toggle,
} from '../../../components'
import { TrashIcon } from '../../../assets/icons'
import { TableOptionsFilters } from '../table-option-filters'

import { SingleSelectForm } from './types'

export const SingleSelectSettings: ComponentType<{
	defaultOptions: TableTypes.Field['valueOptions']
	valueOptionFilters: TableTypes.Field['valueOptionFilters']
	columnId: string
	onClose: () => void
	defaultSingleSelectLookup:
		| {
				tableId: string
				fieldId: string
		  }
		| undefined
}> = ({ defaultOptions, columnId, onClose, defaultSingleSelectLookup, valueOptionFilters }) => {
	const authHeaders = useSelector(authSelectors.getAuthHeaders)

	const { onUpdateColumn, isUpdatingTable, table, tableList, mappedColumns } =
		useContext(TableContext)
	const methods = useForm<SingleSelectForm>({
		defaultValues: {
			options: [...defaultOptions, { _id: v4(), label: '' }],
			single_select_lookup: defaultSingleSelectLookup,
			valueOptionFilters,
		},
	})
	const [toogleValue, setToogleValue] = useState<'custom' | 'lookup' | 'filters'>(
		defaultSingleSelectLookup?.tableId ? 'lookup' : 'custom',
	)
	const { control, watch, handleSubmit, setValue } = methods

	const [columnOptions, setColumnOptions] = useState<{ _id: string; label: string }[]>([])
	const lookupSelected = watch('single_select_lookup')
	const [selectedFilter, setSelectedFilter] = useState('')

	const valueOptionFilterIndex = useMemo(() => {
		if (!valueOptionFilters.length) return 0
		return valueOptionFilters.findIndex(({ _id }) => _id === selectedFilter) ?? -1
	}, [valueOptionFilters, selectedFilter])

	const valueOptionFilterId = useMemo(() => {
		const { _id: valueOptionFilterId } = valueOptionFilters?.find(
			({ _id }) => _id === selectedFilter,
		) ?? { _id: null }
		return valueOptionFilterId
	}, [selectedFilter, valueOptionFilters])

	const { fields, append, remove, update } = useFieldArray({ control, name: 'options' })
	const { append: appendValueOptionFilter, remove: removeValueOptionFilter } = useFieldArray({
		control,
		name: 'valueOptionFilters',
	})

	const optionsWithLabel = fields.filter(({ label }) => label)

	const lastOption = watch(`options.${fields.length - 1}`)
	const penultimateOption = watch(`options.${fields.length - 2}`)

	const handleSubmitOptions = async (data: SingleSelectForm) => {
		const cleanedUpData = getCleanedUpSingleSelectData(data, {
			options: defaultOptions,
			single_select_lookup: defaultSingleSelectLookup,
			valueOptionFilters,
		})
		const response = await onUpdateColumn(columnId, {
			...cleanedUpData,
		})
		if (response.result === 'success' && !valueOptionFilters.length) {
			onClose()
		}
	}

	const toogleOptions = useMemo(() => {
		const defaultToogleOptions = [
			{
				label: 'Custom',
				value: 'custom',
				disabled: !!lookupSelected?.tableId,
			},
			{
				label: 'Table lookup',
				value: 'lookup',
				disabled: fields.length > 1 && toogleValue !== 'filters',
			},
		]
		const column = mappedColumns.get(columnId)
		if (!column) return defaultToogleOptions
		if (!column.valueOptionFilters?.length) return defaultToogleOptions

		return [
			...defaultToogleOptions,
			{
				label: 'Filters',
				value: 'filters',
			},
		]
	}, [mappedColumns, lookupSelected, fields, toogleValue, columnId])

	const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>, index: number) => {
		e.preventDefault()
		e.stopPropagation() // prevents it's handled by onPaste on core table component
		const clipboardData = e.clipboardData
		const pastedData = clipboardData.getData('text')
		if (!pastedData) return

		Papa.parse(pastedData as any, {
			delimiter: '\t',
			newline: '\n',
			skipEmptyLines: true,
			quoteChar: '"',
			complete: (results: ParseResult<string[]>) => {
				const rowsByCell = results.data as string[][]
				const allValues = rowsByCell.map((_row) => _row[0])
				const uniqSplitData = _uniq(allValues)
				uniqSplitData.forEach((data, pastedIndex) => {
					if (pastedIndex === 0) {
						update(index, { _id: lastOption._id, label: data })
					} else {
						append({ _id: v4(), label: data })
					}
				})
			},
		})
	}

	const fetchColumnOptions = useCallback(async () => {
		if (!lookupSelected?.tableId) {
			setColumnOptions([])
			return
		}

		if (lookupSelected.tableId === table?._id) {
			setColumnOptions(
				table.fields.map((_field) => ({
					_id: _field._id,
					label: _field.name,
				})),
			)
			return
		}

		try {
			const response = await TablesSdk.getTable(
				import.meta.env.VITE_API as string,
				authHeaders,
				lookupSelected.tableId,
			)
			setColumnOptions(
				response.fields.map((_field) => ({
					_id: _field._id,
					label: _field.name,
				})),
			)
			return
		} catch (error) {
			showSnackbar('Could not fetch table fields', { variant: 'error' })
			setColumnOptions([])
		}
	}, [lookupSelected?.tableId, table])

	const onAddFilterOptions = useCallback(async () => {
		const valueOptionFilterId = v4()
		const newValueOptionFilter = {
			_id: valueOptionFilterId,
			label: `Filter ${toogleOptions.length - 1}`,
			filters: {
				items: [],
			},
			options: fields.map(({ _id }) => _id),
		}
		const response = await onUpdateColumn(columnId, {
			valueOptionFilters: [...valueOptionFilters, newValueOptionFilter],
		})
		if (response.result === 'success') {
			appendValueOptionFilter(newValueOptionFilter)
			setSelectedFilter(valueOptionFilterId)
		}
	}, [onUpdateColumn, onClose, toogleOptions, fields])

	const onRemove = async (removeId: string) => {
		const response = await onUpdateColumn(columnId, {
			valueOptionFilters: valueOptionFilters.filter(({ _id }) => _id !== removeId),
		})
		if (response.result === 'success') {
			setToogleValue(defaultSingleSelectLookup?.tableId ? 'lookup' : 'custom')
			removeValueOptionFilter(valueOptionFilterIndex)
		}
	}

	useEffect(() => {
		if (lookupSelected?.tableId) {
			fetchColumnOptions()
		}
	}, [lookupSelected?.tableId])

	useEffect(() => {
		if (!fields.length) return
		const lastField = lastOption.label
		if (lastField !== '') {
			append({ _id: v4(), label: '' })
		}

		if (penultimateOption?.label === '' && fields.length > 1) {
			remove(fields.length - 1)
		}
	}, [lastOption?.label, penultimateOption?.label])

	return (
		<Box pb={2}>
			<Text variant="h6">Single select options</Text>
			<Divider />
			<Controller
				control={control}
				name="single_select_lookup"
				render={() => {
					const handleToggleChange = (value: string) => {
						if (value === 'custom') {
							setToogleValue('custom')
							lookupSelected.tableId && setValue('options', [{ _id: v4(), label: '' }])
							setValue('single_select_lookup', { tableId: '', fieldId: '' }, { shouldDirty: true })
							return
						}
						if (value === 'lookup') {
							setToogleValue('lookup')
							!lookupSelected.tableId &&
								setValue('single_select_lookup', { tableId: table?._id ?? '', fieldId: '' })
							setValue('options', [])

							return
						}

						if (value === 'filters') {
							setToogleValue('filters')
							setValue('options', [...defaultOptions])
						}
					}

					return (
						<Toggle
							onChange={(value) => handleToggleChange(value)}
							value={toogleValue}
							options={toogleOptions}
							disabled={!tableList.length}
							sx={{ mt: 1 }}
						/>
					)
				}}
			/>
			{toogleValue === 'custom' && (
				<Box my={1} flex={1}>
					{fields.map((_field, index) => (
						<Box key={_field.id} display="flex" alignItems="center" width="100%" mb={1}>
							<Controller
								control={control}
								name={`options.${index}.label`}
								render={({ field }) => (
									<TextField
										{...field}
										label={`Option ${index + 1}`}
										fullWidth
										size="small"
										containerProps={{ width: 400 }}
										onPaste={(e) => handlePaste(e, index)}
									/>
								)}
							/>
							{index !== fields.length - 1 && (
								<Box display="flex" alignItems="center" pt={2}>
									<IconButton onClick={() => remove(index)}>
										<TrashIcon />
									</IconButton>
								</Box>
							)}
						</Box>
					))}
				</Box>
			)}
			{toogleValue === 'lookup' && (
				<>
					<Controller
						control={control}
						name={'single_select_lookup.tableId'}
						render={({ field: { value, onChange } }) => (
							<Select options={tableList} value={value} onChange={onChange} label="Table" />
						)}
					/>
					<Controller
						control={control}
						name={'single_select_lookup.fieldId'}
						render={({ field: { value, onChange } }) => (
							<Select options={columnOptions} value={value} onChange={onChange} label="Column" />
						)}
					/>
				</>
			)}
			{!['custom', 'lookup'].includes(toogleValue) && (
				<FormProvider key={valueOptionFilterId} {...methods}>
					<Stack direction="column" spacing={1} width="100%" maxWidth={650} mt={1}>
						<Stack direction="column">
							<Text variant="h6">Filter Configuration:</Text>
							<Text variant="caption">
								If a row does not match the condition all options will be displayed
							</Text>
							<Grid container spacing={1}>
								<Grid item xs={11}>
									<Select
										fullWidth
										value={selectedFilter}
										onChange={(e) => setSelectedFilter(e.target.value as string)}
										options={valueOptionFilters.map(({ _id, label }) => ({
											_id,
											label,
										}))}
									/>
								</Grid>
								{selectedFilter && valueOptionFilterId && (
									<Grid item xs={1}>
										<IconButton onClick={() => onRemove(valueOptionFilterId)}>
											<TrashIcon />
										</IconButton>
									</Grid>
								)}
							</Grid>
						</Stack>
						{selectedFilter && valueOptionFilterId && (
							<>
								<Controller
									control={control}
									name={`valueOptionFilters.${valueOptionFilterIndex}.label`}
									render={({ field }) => <TextField fullWidth label="Filter label" {...field} />}
								/>

								<TableOptionsFilters key={valueOptionFilterId} index={valueOptionFilterIndex} />

								<Text>Options avaliable when filter is true</Text>
								<Button
									variant="text"
									onClick={() =>
										setValue(`valueOptionFilters.${valueOptionFilterIndex}.options`, [])
									}
								>
									Unselect all options
								</Button>
								<Controller
									control={control}
									name={`valueOptionFilters.${valueOptionFilterIndex}.options`}
									render={({ field }) => (
										<Select
											{...field}
											fullWidth
											multiple
											options={optionsWithLabel.map(({ _id, label }) => ({
												_id,
												label,
											}))}
										/>
									)}
								/>
							</>
						)}
					</Stack>
				</FormProvider>
			)}
			<Stack direction="row" spacing={2} width={400} my={2}>
				<Button
					fullWidth
					onClick={handleSubmit(handleSubmitOptions)}
					isLoading={isUpdatingTable}
					disabled={!!lookupSelected?.tableId && !lookupSelected?.fieldId}
				>
					Save
				</Button>
				{['custom', 'lookup'].includes(toogleValue) && (
					<Button
						variant="text"
						fullWidth
						onClick={() => {
							remove()
							append({ _id: v4(), label: '' })
							setValue('single_select_lookup', { tableId: '', fieldId: '' }, { shouldDirty: true })
						}}
					>
						Clear All
					</Button>
				)}
			</Stack>
			{optionsWithLabel.length > 0 && (
				<Box width={400}>
					<Button onClick={onAddFilterOptions} fullWidth variant="outlined">
						Add options with filter
					</Button>
				</Box>
			)}
		</Box>
	)
}
