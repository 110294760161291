import { ComponentType } from 'react'
import ToggleButtonGroup, { ToggleButtonGroupProps } from '@mui/material/ToggleButtonGroup'
import ToggleButton, { ToggleButtonProps } from '@mui/material/ToggleButton'
import FormControl, { FormControlProps } from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'

import { colors } from 'src/theme/colors'

import { Box, IBoxProps } from './box'
import { Text } from './text'

type ToggleOption = ToggleButtonProps & {
	label: string
}

type ToggleProps = ToggleButtonGroupProps & {
	onChange: (newValue: any) => void
	options: ToggleOption[]
	label?: string
	containerProps?: IBoxProps
	enforced?: boolean
	formControlProps?: FormControlProps & { helperText?: string }
}

export const Toggle: ComponentType<ToggleProps> = ({
	value,
	onChange,
	options,
	label,
	containerProps,
	enforced = true,
	formControlProps,
	...props
}) => {
	return (
		<Box {...containerProps}>
			<FormControl {...formControlProps} fullWidth>
				{!!label && (
					<Text fontSize={14} color={colors.neutral['80']} fontWeight={500} mb={1}>
						{label}
					</Text>
				)}
				<ToggleButtonGroup
					color={formControlProps?.error ? 'error' : 'primary'}
					value={value}
					onChange={(e, newValue) => {
						if (enforced && newValue === null) return
						onChange(newValue)
					}}
					exclusive
					size="small"
					fullWidth
					{...props}
				>
					{options.map((option) => (
						<ToggleButton
							key={option.label}
							sx={{
								'&.Mui-selected': {
									fontWeight: 600,
								},
							}}
							{...option}
						>
							{option.label}
						</ToggleButton>
					))}
				</ToggleButtonGroup>
				{!!formControlProps?.helperText && (
					<FormHelperText>{formControlProps?.helperText}</FormHelperText>
				)}
			</FormControl>
		</Box>
	)
}
