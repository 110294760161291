import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import findIndex from 'lodash/findIndex'
import _uniqBy from 'lodash/uniqBy'

import * as projectThunks from '../projects-v3/thunks'

import { PersistedConfigState, RecentlyViewedProject } from './types'

export const initialState: PersistedConfigState = {
	recentlyViewedProjects: {},
	isBulkEditDatabasesEnabled: false,
}

export const persistedConfigSlice = createSlice({
	name: 'persisted-config',
	initialState,
	reducers: {
		endSession: () => initialState,
		setHqAxesSwapped: (state, action: PayloadAction<boolean>) => {
			state.hqAxesSwapped = action.payload
		},
		setIsBulkEditDatabasesEnabled: (state, action: PayloadAction<boolean>) => {
			state.isBulkEditDatabasesEnabled = action.payload
		},
		setRecentlyViewedProject: (
			state,
			action: PayloadAction<{ organisationId: string; project: RecentlyViewedProject }>,
		) => {
			if (!state.recentlyViewedProjects[action.payload.organisationId]) {
				state.recentlyViewedProjects[action.payload.organisationId] = [action.payload.project]
				return
			}

			const copiedArray = [...state.recentlyViewedProjects[action.payload.organisationId]]
			const projectIndex = findIndex(copiedArray, ['_id', action.payload.project._id])

			if (projectIndex > 0) {
				copiedArray.splice(projectIndex, 1)
			} else if (copiedArray.length >= 3) {
				copiedArray.pop()
			}
			copiedArray.unshift(action.payload.project)
			state.recentlyViewedProjects[action.payload.organisationId] = _uniqBy(copiedArray, '_id')
		},
		cleanRecentlyViewedProjects: (
			state,
			action: PayloadAction<{ organisationId?: string; projectIds: string[] }>,
		) => {
			if (!action.payload.organisationId) return
			if (!state.recentlyViewedProjects[action.payload.organisationId]) return
			const copiedArray = [...state.recentlyViewedProjects[action.payload.organisationId]]
			state.recentlyViewedProjects[action.payload.organisationId] = copiedArray.filter((project) =>
				action.payload.projectIds.includes(project._id),
			)
		},
	},
	extraReducers: (builder) => {
		builder.addCase(projectThunks.deleteProject.fulfilled, (state, action) => {
			const { projectId, organisationId } = action.payload
			if (!organisationId) return
			if (!state.recentlyViewedProjects[organisationId]) return
			const indexOfProject = findIndex(state.recentlyViewedProjects[organisationId], [
				'_id',
				projectId,
			])
			const copiedArray = [...state.recentlyViewedProjects[organisationId]]
			if (indexOfProject >= 0) {
				copiedArray.splice(indexOfProject, 1)
				state.recentlyViewedProjects[organisationId] = copiedArray
			}
		})
		builder.addCase(projectThunks.updateProject.fulfilled, (state, action) => {
			const {
				response: { _id, name },
				organisationId,
			} = action.payload
			if (!organisationId) return
			if (!state.recentlyViewedProjects[organisationId]) return
			const indexOfProject = findIndex(state.recentlyViewedProjects[organisationId], ['_id', _id])
			const copiedArray = [...state.recentlyViewedProjects[organisationId]]
			if (indexOfProject >= 0) {
				copiedArray[indexOfProject] = { ...copiedArray[indexOfProject], name: name }
				state.recentlyViewedProjects[organisationId] = copiedArray
			}
		})
	},
})
