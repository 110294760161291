import { TableTypes } from '@cango-app/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import * as thunks from './thunks'
import { TableState } from './types'

const initialState: TableState = {
	tables: [],
	mountedTable: undefined,
	isLoadingTables: false,
}

export const tableSlice = createSlice({
	name: 'table',
	initialState,
	reducers: {
		setTables(state: TableState, action: PayloadAction<TableTypes.CangoTable[]>) {
			state.tables = action.payload
		},
		unmountTable: (state) => {
			state.mountedTable = undefined
		},
		endSession: () => initialState,
	},
	extraReducers: (builder) => {
		builder.addCase(thunks.updateRowOrder.fulfilled, (state: TableState, action) => {
			state.mountedTable = action.payload
		})
		builder.addCase(thunks.updateColumnOrder.fulfilled, (state: TableState, action) => {
			state.mountedTable = action.payload
		})
		builder.addCase(thunks.pasteData.fulfilled, (state: TableState, action) => {
			state.mountedTable = action.payload
		})
		builder.addCase(thunks.updateField.fulfilled, (state: TableState, action) => {
			state.mountedTable = action.payload
		})
		builder.addCase(thunks.deleteField.fulfilled, (state: TableState, action) => {
			state.mountedTable = action.payload
		})
		builder.addCase(thunks.lockField.pending, (state: TableState, action) => {
			const { fieldId, lock } = action.meta.arg
			if (state.mountedTable) {
				const updatedFields = state.mountedTable.fields.map((field) =>
					field._id === fieldId ? { ...field, locked: lock } : field,
				)
				state.mountedTable = {
					...state.mountedTable,
					fields: updatedFields,
				}
			}
		})
		builder.addCase(thunks.deleteRecord.fulfilled, (state: TableState, action) => {
			if (state.mountedTable) {
				const updatedRows = state.mountedTable.records.filter(
					(record: any) => record._id !== action.meta.arg.rowId,
				)
				state.mountedTable = {
					...state.mountedTable,
					records: updatedRows,
				}
			}
		})
	},
})
