import React, { ComponentType, useContext, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { GridColumnVisibilityModel } from '@mui/x-data-grid-premium'
import { TableTypes } from '@cango-app/types'

import { Box } from '../box'
import { Select } from '../select'
import { CoreTable } from '../../modules/tables/core-table'
import { TableContext } from '../../providers/table-provider'
import { Button } from '../button'

export const ColumnsAndFilters: ComponentType<{ viewId: string | undefined }> = ({ viewId }) => {
	const { mappedColumns, table, apiRef } = useContext(TableContext)
	const { control } = useFormContext<TableTypes.TableView>()

	const selectedView = useMemo(() => {
		if (!table || !viewId) {
			return
		}

		return table.views.find((view) => view._id === viewId)
	}, [viewId, table?.views])

	const columnList = useMemo(() => {
		return [...mappedColumns.keys()].map((fieldId) => ({
			_id: fieldId,
			label: mappedColumns.get(fieldId)?.name ?? '',
		}))
	}, [mappedColumns])

	if (viewId && !selectedView) {
		return null
	}

	return (
		<Box>
			<Controller
				control={control}
				name={'columns'}
				render={({ field: { value, onChange } }) => {
					const values = columnList.reduce((_selectedValues: string[], _field) => {
						if (value[_field._id] || value[_field._id] === undefined) {
							_selectedValues.push(_field._id)
						}
						return _selectedValues
					}, [])

					const handleSelectChange = (values: string[]) => {
						const newColumnVisibility = columnList.reduce(
							(_acc: GridColumnVisibilityModel, _column) => {
								_acc[_column._id] = values.includes(_column._id)
								return _acc
							},
							{},
						)
						apiRef.current.setColumnVisibilityModel(newColumnVisibility)
						onChange(newColumnVisibility)
					}

					return (
						<Box display="flex">
							<Box width={500}>
								<Select
									label="Columns"
									value={values}
									multiple
									options={columnList}
									onChange={(e) => handleSelectChange(e.target.value as string[])}
									name="columns"
									sx={{ mb: 2 }}
								/>
							</Box>
							<Box display="flex" alignItems="center" onClick={() => handleSelectChange([])}>
								<Button variant="text">Clear all columns</Button>
							</Box>
						</Box>
					)
				}}
			/>
			<Box height={50} width={175}>
				<Controller
					control={control}
					name="filters"
					render={({ field: { onChange } }) => {
						return (
							<CoreTable
								initialState={{
									filter: {
										filterModel: selectedView?.filters ?? {
											items: [],
										},
									},
								}}
								hideFooter
								hideToolbar
								hideColumnSelector
								hideDensitySelector
								isStatic
								onFilterChange={onChange}
								styles={{
									'& .MuiDataGrid-main': {
										display: 'none',
									},
									'& .MuiDataGrid-root': {
										border: 'none !important',
									},
									'& .MuiDataGrid-toolbarContainer': {
										padding: 1,
									},
								}}
							/>
						)
					}}
				/>
			</Box>
		</Box>
	)
}
