import React, { ComponentType, useMemo } from 'react'
import MuiSelect, { SelectProps as MuiSelectProps } from '@mui/material/Select'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Box, { BoxProps } from '@mui/material/Box'
import MuiListSubheader, { ListSubheaderProps } from '@mui/material/ListSubheader'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FormHelperText from '@mui/material/FormHelperText'

import { colors } from 'src/theme/colors'

import { Text } from '../text'

export type GroupedSelectOption = {
	groupName: string
	options: {
		_id: string
		label: string
	}[]
}

export type GroupedSelectProps = Omit<MuiSelectProps, 'variant'> & {
	containerProps?: BoxProps
	isRequired?: boolean
	label: string
	onChange: (id: string) => void
	options: GroupedSelectOption[]
	helperText?: string
	fontSize?: number
	isLoading?: boolean
	variant?: MuiSelectProps['variant']
}

const ListSubheader = (props: ListSubheaderProps) => {
	const { children, ...rest } = props
	return (
		<MuiListSubheader
			sx={{ bgcolor: colors.primary.light, color: 'white', opacity: 0.9 }}
			{...rest}>
			<Text py={1}>
				{children} <ExpandMoreIcon />
			</Text>
		</MuiListSubheader>
	)
}

ListSubheader.muiSkipListHighlight = true

export const GroupedSelect: ComponentType<GroupedSelectProps> = ({
	containerProps,
	isRequired,
	label,
	options,
	error,
	helperText,
	fontSize = 14,
	isLoading,
	...props
}) => {
	const mappedOptions = useMemo(() => {
		return options.reduce((acc: Map<string, string>, opt) => {
			opt.options.forEach((o) => acc.set(o._id, o.label))
			return acc
		}, new Map())
	}, [JSON.stringify(options)])

	const renderSelectGroup = (group: GroupedSelectOption) => {
		const items = group.options.map((option) => {
			return (
				<MenuItem
					key={option._id}
					value={option._id}
					sx={{
						fontSize,
						'&.Mui-selected': {
							fontWeight: 'bold',
						},
					}}>
					{option.label}
				</MenuItem>
			)
		})
		return [<ListSubheader key={group.groupName}>{group.groupName}</ListSubheader>, items]
	}

	return (
		<Box {...containerProps}>
			<Text fontSize={14} color={colors.neutral['80']} fontWeight={500}>
				{label}
			</Text>
			<FormControl fullWidth required={isRequired} disabled={props.disabled} error={error}>
				<MuiSelect
					error={error}
					sx={{ bgcolor: 'white' }}
					displayEmpty={!!props.placeholder}
					renderValue={
						props.multiple
							? (selected) => {
									if ((selected as string[]).length === 0 && props.placeholder) {
										return <Text fontSize={14}>{props.placeholder}</Text>
									}

									return (selected as string[]).map((item) => mappedOptions.get(item)).join(', ')
							  }
							: undefined
					}
					{...props}>
					{!!props.placeholder && (
						<MenuItem value="" disabled sx={{ opacity: 0.5, fontSize }}>
							{props.placeholder}
						</MenuItem>
					)}
					{options?.map((o) => renderSelectGroup(o))}
				</MuiSelect>
				{(!!helperText || !!isLoading) && (
					<FormHelperText error={error}>{isLoading ? 'Loading...' : helperText}</FormHelperText>
				)}
			</FormControl>
		</Box>
	)
}
