import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '../../types'

const getTableState = (state: RootState) => state.cangoTables

const isLoadingTables = createSelector(getTableState, ({ isLoadingTables }) => isLoadingTables)

const getMountedTable = createSelector(getTableState, ({ mountedTable }) => mountedTable)

const getMountedTableId = createSelector(getTableState, ({ mountedTable }) => mountedTable?._id)

const getTableIdLoading = createSelector(getTableState, ({ tableIdLoading }) => tableIdLoading)

export const selectors = {
	isLoadingTables,
	getMountedTable,
	getMountedTableId,
	getTableIdLoading,
}
