import {
	GridColDef,
	GridActionsCellItem,
	GridRowId,
	GridFilterModel,
	GridColumnVisibilityModel,
	DataGridPremium,
	DataGridPremiumProps,
	GridCellParams,
	GridValidRowModel,
	GridRowModelUpdate,
	GridAggregationFunction,
	GRID_AGGREGATION_FUNCTIONS,
	GridInitialState,
	GridSlotsComponentsProps,
} from '@mui/x-data-grid-premium'
import React, { ComponentType, useContext, useMemo, useState } from 'react'
import RestoreIcon from '@mui/icons-material/Restore'
import { useSelector } from 'react-redux'
import { TableTypes } from '@cango-app/types'
import { v4 } from 'uuid'
import _union from 'lodash/union'
import _keys from 'lodash/keys'
import _isEqual from 'lodash/isEqual'
import _reduce from 'lodash/reduce'
import Papa, { ParseResult } from 'papaparse'
import clipboard from 'clipboardy'

import { dataGridTables } from 'src/helpers/ui'
import { selectors as contactSelectors } from 'src/store/modules/contacts'
import { selectors as persistedConfigSelectors } from 'src/store/modules/persisted-config'
import { Box } from 'src/components'
import { TableContext } from 'src/providers/table-provider'

import { showSnackbar } from '../../helpers/snackbarManager'

import { CustomColumnMenu } from './column-menu'
import { resolveAnyRowCalculations } from './utils'
import { CustomToolbar } from './toolbar'
import { CustomFooter } from './footer'
import { CustomNoRowsOverlay } from './no-rows'
import { ColumnSettingsModal } from './column-settings/column-settings-modal'
import { useColumnFormatter } from './use-column-formatter'

type CoreTableProps = {
	rowReordering?: boolean
	hideFooter?: boolean
	onFilterChange?: (filters: GridFilterModel) => void
	onColumnFiltersChange?: (columns: GridColumnVisibilityModel) => void
	isStatic?: boolean
	onCellClick?: (data: { columnId: string; rowId: string; cellId: string }) => void
	selectedCell?: string
	isLocked?: boolean
	styles?: DataGridPremiumProps['sx']
	hideToolbar?: boolean
	hideTable?: boolean
	initialState?: GridInitialState
	hideDensitySelector?: boolean
	hideColumnSelector?: boolean
	hideFilterSelector?: boolean
}

const slots = {
	toolbar: CustomToolbar,
	columnSortedDescendingIcon: null,
	columnSortedAscendingIcon: null,
	columnMenu: CustomColumnMenu,
	footer: CustomFooter,
	noRowsOverlay: CustomNoRowsOverlay,
	noResultsOverlay: CustomNoRowsOverlay,
}

export const cangoTableAggregationList = [
	...Object.keys(GRID_AGGREGATION_FUNCTIONS).map((agg) => ({
		_id: agg,
		label: agg,
	})),
	{ _id: 'concatenate', label: 'concatenate' },
	{
		_id: 'value',
		label: 'value',
	},
]

export const getRowDifference = (oldRow: any, newRow: any) => {
	const keys = _union(_keys(oldRow), _keys(newRow))

	return _reduce(
		keys,
		(result: string[], key) => {
			if (!_isEqual(oldRow[key], newRow[key])) {
				result.push(key)
			}
			return result
		},
		[],
	)
}

export const concatenateAggregation: GridAggregationFunction<string, string | null> = {
	apply: (params) => {
		if (!params.values.length) {
			return null
		}

		return params.values.join(', ')
	},
	label: 'concatenate',
}

export const valueAggregation: GridAggregationFunction<string, any> = {
	apply: (params) => {
		if (!params.values.length) {
			return null
		}

		return params.values[0]
	},
	label: 'value',
}

export const cangoTableAggregations: Record<string, GridAggregationFunction> = {
	...GRID_AGGREGATION_FUNCTIONS,
	concatenate: concatenateAggregation,
	value: valueAggregation,
}

const UndecoratedTable: ComponentType<CoreTableProps> = ({
	onFilterChange,
	rowReordering = false,
	hideFooter = false,
	onColumnFiltersChange,
	isStatic,
	onCellClick,
	selectedCell,
	isLocked,
	hideToolbar,
	styles,
	initialState,
	hideColumnSelector = false,
	hideDensitySelector = false,
	hideFilterSelector = false,
	hideTable = false,
}) => {
	const {
		table,
		cacheRowUpdate,
		unsavedChanges,
		discardChange,
		isUpdatingTable,
		onColumnOrderChange,
		onRowOrderChange,
		isLoadingTable,
		cacheNewRow,
		saveChanges,
		apiRef,
		sortingModel,
		updateSortingModel,
		onUpdateColumn,
		updateTableConfig,
	} = useContext(TableContext)
	const mappedContacts = useSelector(contactSelectors.mappedContacts)
	const isBulkEditEnabled = useSelector(persistedConfigSelectors.getIsBulkEditDatabasesEnabled)
	const [filterButtonEl, setFilterButtonEl] = useState<HTMLButtonElement | null>(null)
	const [settingsModalId, setSettingsModalId] = useState<string | null>(null)
	const [isGrouped, setIsGrouped] = useState(false)
	const { columns } = useColumnFormatter({
		apiRef,
		isTableLocked: !!isLocked,
		isBulkEditEnabled,
		sortingModel,
	})

	const rows = useMemo(() => {
		if (!table?.records) return []
		return table.records.map((row) => {
			return resolveAnyRowCalculations({
				row,
				fields: table.fields,
				contacts: mappedContacts,
			})
		})
	}, [table?.records, table?.fields])

	const processRowUpdate: NonNullable<DataGridPremiumProps['processRowUpdate']> = async (
		newRow,
		oldRow,
	) => {
		const cachedValue = cacheRowUpdate(newRow, oldRow)
		if (!isBulkEditEnabled) {
			await saveChanges()
		}

		apiRef.current.setCellSelectionModel({})

		setTimeout(() => {
			updateSimilarRows(newRow, oldRow)
		}, 500)
		return cachedValue
	}

	const updateSimilarRows: NonNullable<DataGridPremiumProps['processRowUpdate']> = (
		newRow,
		oldRow,
	) => {
		const rowDifference = getRowDifference(oldRow, newRow)

		const rowUpdates: GridRowModelUpdate[] = []
		rowDifference.forEach((rowKey) => {
			const columnType = table?.fields.find((field) => field._id === rowKey)?.type
			if (
				columnType === TableTypes.FieldType.CONTACT &&
				!mappedContacts.has(String(oldRow[rowKey])) &&
				mappedContacts.has(String(newRow[rowKey]))
			) {
				table?.records.forEach((_row) => {
					if (_row._id !== newRow._id && _row.data[rowKey] === oldRow[rowKey]) {
						const _newRow = {
							..._row.data,
							_id: _row._id,
							[rowKey]: newRow[rowKey],
						}
						const cachedRowUpdate = cacheRowUpdate(_newRow, { ..._row.data, _id: _row._id })
						rowUpdates.push({ id: _row._id, ...cachedRowUpdate })
					}
				})
			}
		})

		if (rowUpdates.length) {
			apiRef.current.updateRows(rowUpdates)
		}
	}

	const processNewRow = (newRow: GridValidRowModel) => {
		return cacheNewRow(newRow)
	}

	const handlePaste = async (event: React.ClipboardEvent) => {
		event.preventDefault()
		let pastedText = event.clipboardData.getData('text')

		pastedText = pastedText.replace(/\r\n/g, '\n').replace(/\r/g, '\n')

		Papa.parse(pastedText as any, {
			delimiter: '\t',
			newline: '\n',
			skipEmptyLines: true,
			quoteChar: '"',
			complete: (results: ParseResult<string[]>) => {
				const rowsByCell = results.data as string[][]
				const eventTarget = event.target as HTMLElement

				const columnId = eventTarget.closest('div[data-field]')?.getAttribute('data-field')
				const rowId = eventTarget.closest('div[data-rowindex]')?.getAttribute('data-id')
				apiRef.current.stopCellEditMode({
					id: rowId as GridRowId,
					field: columnId ?? '',
					ignoreModifications: true,
				})

				if (!columnId || !rowId) return
				const rowIndex = rows.findIndex((row) => row._id === rowId)
				const columnIndex = columns.findIndex((column) => column.field === columnId)
				if (rowIndex === -1 || columnIndex === -1) return

				const rowUpdates: GridRowModelUpdate[] = []
				rowsByCell.forEach((_row, distanceFromPastingRow) => {
					const updatedCells: GridValidRowModel = {}
					_row.forEach((_cell, distanceFromPastingColumn) => {
						const cellColumn = columns[columnIndex + distanceFromPastingColumn]
						if (!cellColumn) return
						let value: any = _cell
						if (cellColumn.type === 'number') {
							value = Number(_cell.replace(/[^0-9.-]+/g, ''))
						}
						updatedCells[cellColumn.field] = value
					})

					const dataGridRow = rows[rowIndex + distanceFromPastingRow]
					if (!dataGridRow) {
						const id = v4()
						const newRow = processNewRow({ id, _id: id, ...updatedCells })
						rowUpdates.push({ id: newRow._id, ...newRow })
						return
					}
					const rowCopy = { ...dataGridRow, ...updatedCells }
					const updatedRow = cacheRowUpdate(rowCopy, dataGridRow)
					rowUpdates.push({ id: updatedRow._id, ...updatedRow })
				})

				apiRef.current.updateRows(rowUpdates)
			},
		})
	}

	const columnsWithActions = useMemo<GridColDef[]>(() => {
		if (isStatic) return columns
		return isBulkEditEnabled
			? [
					{
						field: 'Actions',
						type: 'actions',
						getActions: ({ id }) => {
							return [
								...(isBulkEditEnabled
									? [
											<GridActionsCellItem
												key={`${id}-restore`}
												icon={<RestoreIcon />}
												label="Discard changes"
												disabled={
													unsavedChanges.unsavedRows[id] === undefined &&
													unsavedChanges.newRows[id] === undefined
												}
												onClick={() => {
													const isNewRow = !!unsavedChanges.newRows[id]
													if (isNewRow) {
														apiRef.current.updateRows([{ id, _id: id, _action: 'delete' }])
													} else {
														apiRef.current.updateRows([
															{ ...unsavedChanges.rowsBeforeChange[id], id },
														])
													}

													discardChange(id as string)
												}}
											/>,
										]
									: []),
							]
						},
					},
					...columns,
				]
			: columns
	}, [columns, JSON.stringify(unsavedChanges)])

	const memoizedSlotProps = useMemo(
		(): GridSlotsComponentsProps => ({
			toolbar: {
				printOptions: { disableToolbarButton: true },
				csvOptions: { disableToolbarButton: true },
				isStatic,
				isLocked,
				hideToolbar,
				setFilterButtonEl,
				hideColumnSelector,
				hideDensitySelector,
				hideFilterSelector,
			},
			baseCheckbox: {
				size: 'small',
			},
			columnMenu: {
				slotProps: {
					openSettings: setSettingsModalId,
				},
			},
			panel: {
				anchorEl: filterButtonEl,
			},
			loadingOverlay: {
				variant: 'linear-progress',
			},
		}),
		[
			isStatic,
			isLocked,
			hideToolbar,
			hideColumnSelector,
			hideDensitySelector,
			hideFilterSelector,
			filterButtonEl,
		],
	)

	return (
		<Box>
			<ColumnSettingsModal onClose={() => setSettingsModalId(null)} columnId={settingsModalId} />
			<Box
				width="100%"
				height="100%"
				onPaste={handlePaste}
				display={hideTable ? 'none' : undefined}
			>
				<DataGridPremium
					apiRef={apiRef}
					initialState={initialState}
					rows={rows}
					columns={columnsWithActions}
					density={table?.row_density}
					onDensityChange={(params) => {
						updateTableConfig({ row_density: params })
					}}
					getRowId={(row) => row._id}
					rowReordering={!isGrouped && rowReordering}
					pinnedColumns={{
						left: table?.principal_field ? ['__reorder__', '__check__', table.principal_field] : [],
					}}
					onColumnWidthChange={(params) => {
						onUpdateColumn(params.colDef.field, { width: params.width })
					}}
					hideFooter={hideFooter}
					onRowOrderChange={onRowOrderChange}
					onColumnOrderChange={onColumnOrderChange}
					processRowUpdate={processRowUpdate}
					onProcessRowUpdateError={(error) => {
						showSnackbar(error?.message, { variant: 'error' })
					}}
					checkboxSelection={true}
					sortModel={sortingModel}
					onSortModelChange={(newModel) => updateSortingModel(newModel)}
					onColumnVisibilityModelChange={onColumnFiltersChange}
					disableClipboardPaste
					//@ts-ignore doesn't make sense that this is throwing an error. I cannot seem to fix it.
					sx={{ ...dataGridTables, ...styles, ...(hideTable ? { display: 'none' } : {}) }}
					showCellVerticalBorder
					showColumnVerticalBorder
					ignoreValueFormatterDuringExport
					disableRowSelectionOnClick
					disableColumnMenu={isStatic}
					getAggregationPosition={() => 'inline'}
					loading={isUpdatingTable || isLoadingTable}
					groupingColDef={{ width: 250 }}
					aggregationFunctions={cangoTableAggregations}
					onRowGroupingModelChange={(groupingModel) => {
						if (groupingModel.length && !isGrouped) {
							setIsGrouped(true)
						} else if (!groupingModel.length && isGrouped) {
							setIsGrouped(false)
						}
					}}
					rowGroupingColumnMode="multiple"
					onFilterModelChange={onFilterChange}
					cellSelection
					onClipboardCopy={(copiedString) => {
						const lines = copiedString.split(/\r?\n/)
						const output = lines.join('\n')
						clipboard.write(output.replace(/"/g, '\\"'))
					}}
					onCellClick={async (params) => {
						if (['group'].includes(params.rowNode.type)) return // prevent error if it's autogenerated column)
						if (params.field === '__check__') return
						if (params.colDef.type === 'boolean') {
							if (isUpdatingTable) {
								return
							}
							const newValue = !params.value
							const cachedUpdate = await processRowUpdate(
								{ ...params.row, [params.field]: newValue },
								params.row,
							)
							if (cachedUpdate) {
								apiRef.current.updateRows([{ id: params.row._id, ...cachedUpdate }])
							}
							return
						}
						const colType = table?.fields.find((field) => field._id === params.field)?.type
						if (
							colType &&
							[TableTypes.FieldType.VIDEO, TableTypes.FieldType.URL].includes(colType)
						) {
							return
						}
						if (onCellClick) {
							onCellClick({
								columnId: params.field,
								rowId: params.row._id,
								cellId: params.id as string,
							})
						}
					}}
					getCellClassName={(params: GridCellParams) => {
						if (params.colDef?.type === 'boolean' && params.isEditable) {
							return 'cell-checkbox'
						}
						if (selectedCell) {
							const [columnId, rowId] = selectedCell.split('-')
							if (params.id === rowId && params.field === columnId) {
								return 'cell--selected'
							}
						}
						return ''
					}}
					getRowClassName={(params) => {
						const unsavedRow = unsavedChanges.unsavedRows[params.id]
						if (unsavedRow) {
							if (unsavedRow._action === 'delete') {
								return 'row--removed'
							}
							return 'row--edited'
						}
						const newRow = unsavedChanges.newRows[params.id]
						if (newRow) {
							return 'row--added'
						}
						return ''
					}}
					slots={slots}
					slotProps={memoizedSlotProps}
				/>
			</Box>
		</Box>
	)
}

export const CoreTable = React.memo(UndecoratedTable)
