import React, { ComponentType } from 'react'
import { useSelector } from 'react-redux'

import { selectors as userSelectors } from 'src/store/modules/user'

import error403 from '../../assets/images/403.jpg'

type RenderForProps = {
	permissions?: string[]
	children: any
	entirePage?: boolean
}

export const RenderFor: ComponentType<RenderForProps> = ({
	permissions = ['staff'],
	children,
	entirePage = false,
}) => {
	const userPermissions = useSelector(userSelectors.getPermissions)
	if (permissions.includes(userPermissions || '') || userPermissions === 'techmaster') {
		return <>{children}</>
	}

	if (!entirePage) return null

	return (
		<div className="Page-under-construction">
			<img src={error403} alt="Access Restricted" />
		</div>
	)
}
