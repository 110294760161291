import React, { ComponentType, useContext, useEffect, useState } from 'react'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import { TablesSdk } from '@cango-app/sdk'
import { TableTypes } from '@cango-app/types'
import { useSelector } from 'react-redux'
import { PulseLoader } from 'react-spinners'

import { selectors as authSelectors } from 'src/store/modules/auth'

import { Modal } from '../modal'
import { Box } from '../box'
import { TableContext } from '../../providers/table-provider'
import { Text } from '../text'
import { colors } from '../../theme/colors'
import { Button } from '../button'
import { showSnackbar } from '../../helpers/snackbarManager'
import { DisplayView } from '../display-view'
import { DisplayToggle } from '../display-view/display-toggle'

import { ColumnsAndFilters } from './columns-and-filters'
import { ViewSettings } from './view-settings'
import { Grouping } from './grouping'
import { GroupChildren } from './group-children'
import { getDefaultValues } from './getDefaultValues'

const DisplayTable: ComponentType = () => {
	const { watch } = useFormContext<TableTypes.TableView>()
	const formFields = watch()
	return <DisplayView {...formFields} datagridProps={{ disableColumnSelector: false }} />
}

const ViewForm: ComponentType<{
	onViewSave: (data: { views: TableTypes.TableView[]; newViewId: string | undefined }) => void
	viewId: string | undefined
}> = ({ onViewSave, viewId }) => {
	const { table } = useContext(TableContext)
	const formControl = useForm<TableTypes.TableView>({
		defaultValues: getDefaultValues({ tableViews: table?.views ?? [], viewId }),
	})
	const { handleSubmit, reset } = formControl

	const [expandedStage, setExpandedStage] = useState<number | false>(0)
	const [isSavingView, setIsSavingView] = useState(false)

	const authHeaders = useSelector(authSelectors.getAuthHeaders)

	const handleAccordionChange =
		(panel: number) => (event: React.SyntheticEvent, newExpanded: boolean) => {
			setExpandedStage(newExpanded ? panel : false)
		}

	const handleSaveView = async (data: TableTypes.TableView) => {
		if (!table) return
		setIsSavingView(true)

		if (data.displayMode === TableTypes.ViewStyleMode.presentation) {
			data.displayChildren = true
			data.subRowColumns = {}
		}
		try {
			let updatedViews: { views: TableTypes.TableView[]; newViewId: string | undefined }
			if (viewId) {
				const response = await TablesSdk.updateView(
					import.meta.env.VITE_API as string,
					authHeaders,
					table._id,
					viewId,
					data,
				)
				updatedViews = {
					views: response,
					newViewId: undefined,
				}
			} else {
				updatedViews = await TablesSdk.createView(
					import.meta.env.VITE_API as string,
					authHeaders,
					table._id,
					data,
				)
			}
			onViewSave(updatedViews)
		} catch (err) {
			showSnackbar('Error creating view', { variant: 'error' })
		} finally {
			setIsSavingView(false)
		}
	}

	useEffect(() => {
		if (table?._id && viewId) {
			const viewParams = table.views.find((_view) => _view._id === viewId)
			reset(viewParams)
		}
	}, [table?._id, viewId])

	const isEditableMode = formControl.getValues('displayMode') === TableTypes.ViewStyleMode.editable

	return (
		<FormProvider {...formControl}>
			<Box width="95vw" height="88vh" display="flex" flexDirection="column" position="relative">
				<Box height="80%" sx={{ flexGrow: 1, overflowY: 'auto', marginBottom: 4 }}>
					<Accordion expanded={expandedStage === 0} onChange={handleAccordionChange(0)}>
						<AccordionSummary>
							<Text variant="h6">View settings</Text>
						</AccordionSummary>
						<AccordionDetails>
							<ViewSettings />
						</AccordionDetails>
					</Accordion>
					<Accordion expanded={expandedStage === 1} onChange={handleAccordionChange(1)}>
						<AccordionSummary>
							<Text variant="h6">Columns and filters</Text>
						</AccordionSummary>
						<AccordionDetails>
							<ColumnsAndFilters viewId={viewId} />
						</AccordionDetails>
					</Accordion>
					<Accordion expanded={expandedStage === 2} onChange={handleAccordionChange(2)}>
						<AccordionSummary>
							<Text variant="h6">Grouping</Text>
						</AccordionSummary>
						<AccordionDetails>
							<Grouping />
						</AccordionDetails>
					</Accordion>
					<Accordion expanded={expandedStage === 3} onChange={handleAccordionChange(3)}>
						<AccordionSummary>
							<Text variant="h6">View Style</Text>
						</AccordionSummary>
						<AccordionDetails>
							<DisplayToggle />
						</AccordionDetails>
					</Accordion>
					{isEditableMode && (
						<Accordion
							expanded={expandedStage === 4}
							onChange={handleAccordionChange(4)}
							sx={{ mb: 2 }}
						>
							<AccordionSummary>
								<Text variant="h6">Children</Text>
							</AccordionSummary>
							<AccordionDetails>
								<GroupChildren />
							</AccordionDetails>
						</Accordion>
					)}
					<Accordion expanded>
						<AccordionSummary>
							<Text variant="h6">Preview</Text>
						</AccordionSummary>
						<AccordionDetails>
							<Box height="auto">
								<FormProvider {...formControl}>
									<DisplayTable />
								</FormProvider>
							</Box>
						</AccordionDetails>
					</Accordion>
				</Box>
				<Box position="fixed" bottom={0}>
					<Button onClick={handleSubmit(handleSaveView)} isLoading={isSavingView} sx={{ mt: 2 }}>
						Save view
					</Button>
				</Box>
			</Box>
		</FormProvider>
	)
}

const CreateViewLoadingContainer: ComponentType<{
	viewId: string | undefined
	onViewSave: (data: { views: TableTypes.TableView[]; newViewId: string | undefined }) => void
}> = ({ viewId, onViewSave }) => {
	const { table, isLoadingTable } = useContext(TableContext)

	if (isLoadingTable) {
		return (
			<Box display="flex" justifyContent="center" alignItems="center">
				<PulseLoader />
			</Box>
		)
	}

	if (!table) {
		return null
	}

	return <ViewForm onViewSave={onViewSave} viewId={viewId} />
}

const CreateViewContainer: ComponentType<{
	open: boolean
	onClose: () => void
	viewId: string | undefined
	onViewSave: (data: { views: TableTypes.TableView[]; newViewId: string | undefined }) => void
}> = ({ open, onClose, viewId, onViewSave }) => {
	return (
		<Modal
			open={open}
			onClose={onClose}
			containerStyle={{ bgcolor: colors.neutral['10'], maxHeight: 'auto' }}
		>
			<CreateViewLoadingContainer onViewSave={onViewSave} viewId={viewId} />
		</Modal>
	)
}

export default CreateViewContainer
