import React, { ComponentType, memo, useContext, useMemo } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import { TableTypes } from '@cango-app/types'
import { GridColumnVisibilityModel } from '@mui/x-data-grid-premium'

import { Checkbox as CangoCheckbox } from '../checkbox'
import { Box } from '../box'
import { TableContext } from '../../providers/table-provider'
import { Select } from '../select'

export const GroupChildren: ComponentType = memo(function GroupChildren() {
	const { control } = useFormContext<TableTypes.TableView>()
	const { mappedColumns } = useContext(TableContext)
	const groupedField: string | undefined = useWatch<TableTypes.TableView>({ name: 'groupedField' })

	const columnList = useMemo(() => {
		return [...mappedColumns.keys()].reduce((_acc: { _id: string; label: string }[], _fieldId) => {
			if (_fieldId === groupedField) {
				return _acc
			}
			_acc.push({
				_id: _fieldId,
				label: mappedColumns.get(_fieldId)?.name ?? '',
			})
			return _acc
		}, [])
	}, [mappedColumns])

	return (
		<Box>
			<Box mb={2}>
				<Controller
					control={control}
					name={'displayChildren'}
					render={({ field: { value, onChange } }) => {
						return (
							<CangoCheckbox checked={value} onChange={onChange} label="Show grouped children" />
						)
					}}
				/>
			</Box>
			<Box>
				<Controller
					control={control}
					name="subRowColumns"
					render={({ field: { value, onChange } }) => {
						const values = columnList.reduce((_selectedValues: string[], _field) => {
							if (value?.[_field._id] || value?.[_field._id] === undefined) {
								_selectedValues.push(_field._id)
							}
							return _selectedValues
						}, [])

						const handleSelectChange = (values: string[]) => {
							const newColumnVisibility = columnList.reduce(
								(_acc: GridColumnVisibilityModel, _column) => {
									_acc[_column._id] = values.includes(_column._id)
									return _acc
								},
								{},
							)
							onChange(newColumnVisibility)
						}

						return (
							<Select
								options={columnList}
								multiple
								label="Select columns to display"
								value={values}
								onChange={(e) => handleSelectChange(e.target.value as string[])}
								withNoneOption
								size="small"
							/>
						)
					}}
				/>
			</Box>
		</Box>
	)
})
