import { ComponentType, memo } from 'react'
import { Stack } from '@mui/material'

import { Box, Chip, Text } from '../../../../components'

import { formulasWithLabels } from './utils'

type FormulaOperatorsProps = {
	activeOperatorId: string | undefined
	onFormulaClick: (formulaId: string | undefined) => void
}

const OperatorChip: ComponentType<FormulaOperatorsProps & { _id: string; label: string }> = memo(
	function OperatorChip({ _id, label, activeOperatorId, onFormulaClick }) {
		return (
			<Box marginRight={1}>
				<Chip
					variant={activeOperatorId === _id ? 'filled' : 'outlined'}
					color="primary"
					label={label.toUpperCase()}
					onClick={() => onFormulaClick(activeOperatorId === _id ? undefined : _id)}
				/>
			</Box>
		)
	},
)

export const FormulaOperators: ComponentType<FormulaOperatorsProps> = (props) => {
	return (
		<Stack direction="column" spacing={1}>
			<Text variant="overline">Formulas</Text>
			<Stack
				direction="row"
				sx={{
					overflowX: 'auto',
				}}
			>
				{formulasWithLabels.map((_formula) => (
					<OperatorChip key={_formula._id} {..._formula} {...props} />
				))}
			</Stack>
		</Stack>
	)
}
