import { Dispatch, createAsyncThunk } from '@reduxjs/toolkit'
import { TablesSdk } from '@cango-app/sdk'

import { showSnackbar } from 'src/helpers/snackbarManager'

import { errorHandler } from '../../../helpers/api'
import { selectors as authSelectors } from '../auth'
import { RootState } from '../../types'

export const updateColumnOrder = createAsyncThunk<
	TablesSdk.UpdateColumnOrderResponse,
	TablesSdk.UpdateColumnOrderRequest,
	{ rejectValue: string; dispatch: Dispatch }
>('table/updateColumnOrder', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState() as RootState
		const headers = authSelectors.getAuthHeaders(state)
		const response = await TablesSdk.updateColumnOrder(
			import.meta.env.VITE_API as string,
			headers,
			data,
		)

		return response
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue((error as Error).message)
	}
})

export const updateRowOrder = createAsyncThunk<
	TablesSdk.UpdateRowOrderResponse,
	TablesSdk.UpdateRowOrderRequest,
	{ rejectValue: string; dispatch: Dispatch }
>('table/updateRowOrder', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState() as RootState
		const headers = authSelectors.getAuthHeaders(state)
		const response = await TablesSdk.updateRowOrder(
			import.meta.env.VITE_API as string,
			headers,
			data,
		)

		return response
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue((error as Error).message)
	}
})

export const pasteData = createAsyncThunk<
	TablesSdk.PasteDataResponse,
	TablesSdk.PasteDataRequest,
	{ rejectValue: string; dispatch: Dispatch }
>('table/pasteData', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState() as RootState
		const headers = authSelectors.getAuthHeaders(state)
		const response = await TablesSdk.pasteData(import.meta.env.VITE_API as string, headers, data)
		return response
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue((error as Error).message)
	}
})

export const deleteRecord = createAsyncThunk<
	TablesSdk.DeleteRecordResponse,
	TablesSdk.DeleteRecordRequest,
	{ rejectValue: string; dispatch: Dispatch }
>('table/deleteRecord', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState() as RootState
		const headers = authSelectors.getAuthHeaders(state)
		const response = await TablesSdk.deleteRecord(import.meta.env.VITE_API as string, headers, data)

		return response
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue((error as Error).message)
	}
})

export const addColumn = createAsyncThunk<TablesSdk.AddColumnResponse, TablesSdk.AddColumnRequest>(
	'table/addColumn',
	async (data, { getState, rejectWithValue, dispatch }) => {
		try {
			const state = getState() as RootState
			const headers = authSelectors.getAuthHeaders(state)
			const response = await TablesSdk.addColumn(import.meta.env.VITE_API as string, headers, data)
			return response
		} catch (error) {
			errorHandler({ error, dispatch })
			return rejectWithValue(error as any)
		}
	},
)

export const updateField = createAsyncThunk<
	TablesSdk.UpdateFieldResponse,
	TablesSdk.UpdateFieldRequest & { tableId: string; fieldId: string }
>(
	'table/updateField',
	async ({ tableId, fieldId, ...data }, { getState, rejectWithValue, dispatch }) => {
		try {
			const state = getState() as RootState
			const headers = authSelectors.getAuthHeaders(state)
			const response = await TablesSdk.updateField(
				import.meta.env.VITE_API as string,
				headers,
				tableId,
				fieldId,
				data,
			)
			return response
		} catch (error) {
			errorHandler({ error, dispatch })
			return rejectWithValue(error as any)
		}
	},
)

export const lockField = createAsyncThunk<
	TablesSdk.LockFieldResponse,
	TablesSdk.LockFieldRequest & { fieldName: string }
>('table/lockField', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState() as RootState
		const headers = authSelectors.getAuthHeaders(state)
		const response = await TablesSdk.lockField(import.meta.env.VITE_API as string, headers, data)
		const { lock, fieldName } = data
		showSnackbar(`${fieldName} field ${lock ? 'locked' : 'unlocked'}`, { variant: 'success' })
		return response
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue(error as any)
	}
})

export const deleteField = createAsyncThunk<
	TablesSdk.DeleteFieldResponse,
	TablesSdk.DeleteFieldRequest
>('table/deleteField', async (data, { getState, rejectWithValue, dispatch }) => {
	try {
		const state = getState() as RootState
		const headers = authSelectors.getAuthHeaders(state)
		const response = await TablesSdk.deleteField(import.meta.env.VITE_API as string, headers, data)
		return response
	} catch (error) {
		errorHandler({ error, dispatch })
		return rejectWithValue(error as any)
	}
})
