import { ComponentType, useState } from 'react'
import _toNumber from 'lodash/toNumber'
import { IconButton } from '@mui/material'
import { TableTypes } from '@cango-app/types'
import { v4 } from 'uuid'

import { SaveIcon } from '../../../../assets/icons'
import { Grid, TextField } from '../../../../components'

type NumberInputProps = {
	onAddNumber: (data: TableTypes.FormulaSlice) => void
}

export const NumberInput: ComponentType<NumberInputProps> = ({ onAddNumber }) => {
	const [input, setInput] = useState('')

	const handleAddNumber = () => {
		onAddNumber({
			id: v4(),
			type: TableTypes.FormulaSliceType.NUMBER,
			value: input,
		})
		setInput('')
	}

	return (
		<Grid item xs={12} mb={2}>
			<TextField
				label="Number"
				fullWidth
				value={input}
				onChange={(event) => setInput(String(event.target.value))}
				type="number"
				onKeyDown={(ev) => {
					const numberKey = _toNumber(ev.key)
					if (isNaN(numberKey) && ev.key !== 'Backspace' && ev.key !== 'Delete' && ev.key !== '.') {
						ev.preventDefault()
					}
					if (ev.key === 'Enter') {
						handleAddNumber()
					}
				}}
				InputProps={{
					endAdornment: (
						<IconButton onClick={handleAddNumber}>
							<SaveIcon />
						</IconButton>
					),
				}}
			/>
		</Grid>
	)
}
