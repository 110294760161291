import { createSlice } from '@reduxjs/toolkit'
import _merge from 'lodash/merge'

import * as thunks from './thunks'
import { RolesState } from './types'

const initialState: RolesState = {
	roles: [],
}

export const roleSlice = createSlice({
	name: 'roles',
	initialState,
	reducers: {
		endSession: () => initialState,
	},
	extraReducers: (builder) => {
		builder.addCase(thunks.getRoles.fulfilled, (state: RolesState, action) => {
			state.roles = _merge(state.roles, action.payload)
		})
		builder.addCase(thunks.getRolesWithUsage.fulfilled, (state: RolesState, action) => {
			state.roles = action.payload
		})
		builder.addCase(thunks.addRole.fulfilled, (state: RolesState, action) => {
			state.roles = action.payload ?? state.roles
		})
		builder.addCase(thunks.updateRole.fulfilled, (state: RolesState, action) => {
			state.roles = action.payload ?? state.roles
		})
		builder.addCase(thunks.deleteRole.fulfilled, (state: RolesState, action) => {
			const roleIndex = state.roles.map(({ _id }) => _id).indexOf(action.payload)
			state.roles.splice(roleIndex, 1)
		})
	},
})
