import { ComponentType, useContext, useMemo } from 'react'
import _isEmpty from 'lodash/isEmpty'
import { Stack } from '@mui/material'
import { TableTypes } from '@cango-app/types'
import { v4 } from 'uuid'

import { colors } from '../../../../theme/colors'
import { Box, Chip, Grid, Text } from '../../../../components'
import { TableContext } from '../../../../providers/table-provider'

type ReferenceColumnProps = {
	onFieldClick: (data: TableTypes.FormulaSlice) => void
}

const ColumnsInReferencedTable: ComponentType<
	ReferenceColumnProps & {
		referenceColumn: string
		_id: string
		label: string
	}
> = ({ referenceColumn, _id, label, onFieldClick }) => {
	return (
		<Box marginRight={1} mb={1}>
			<Chip
				color="primary"
				variant="outlined"
				label={label}
				onClick={() =>
					onFieldClick({
						id: v4(),
						type: TableTypes.FormulaSliceType.REFERENCE_FIELD,
						reference_column: referenceColumn,
						value: _id,
					})
				}
			/>
		</Box>
	)
}

const ReferenceColumn: ComponentType<ReferenceColumnProps & { columnId: string }> = ({
	columnId,
	...rest
}) => {
	const { table, mappedColumns } = useContext(TableContext)
	const column = mappedColumns.get(columnId)

	const referencedTableColumnNames = useMemo(() => {
		if (!table?.referenceColumnNames || _isEmpty(table.referenceColumnNames)) {
			return []
		}
		return table.referenceColumnNames[columnId]
	}, [table?.referenceColumnNames])

	if (!column) {
		return null
	}

	return (
		<Grid item xs={12} mb={2}>
			<Text component="label" fontSize={14} color={colors.neutral['80']} fontWeight={500}>
				{column.name}
			</Text>
			<Stack
				direction="row"
				flexWrap="wrap"
				marginY={0.5}
				sx={{
					overflowX: 'auto',
				}}
			>
				{referencedTableColumnNames.map(({ _id, label }) => (
					<ColumnsInReferencedTable
						{...rest}
						key={_id}
						_id={_id}
						label={label}
						referenceColumn={columnId}
					/>
				))}
			</Stack>
		</Grid>
	)
}

export const ReferenceColumnList: ComponentType<ReferenceColumnProps> = (props) => {
	const { table } = useContext(TableContext)

	const referenceColumnIds = useMemo(() => {
		if (!table?.referenceColumnNames || _isEmpty(table.referenceColumnNames)) {
			return []
		}
		return Object.keys(table.referenceColumnNames).filter(
			(_columnId) => table.referenceColumnNames[_columnId].length,
		)
	}, [table?.referenceColumnNames])

	if (!referenceColumnIds.length) {
		return null
	}

	return (
		<>
			{referenceColumnIds.map((_columnId) => (
				<ReferenceColumn {...props} key={_columnId} columnId={_columnId} />
			))}
		</>
	)
}
