import { TableTypes } from '@cango-app/types'

import { getFieldType } from '../../mui-formatter'

import { FormulaType } from './types'

export const simpleFormulas: FormulaType[] = ['AVERAGE', 'SUM', 'COUNT']

export const formulas: FormulaType[] = [
	...simpleFormulas,
	'SUMIF',
	'COUNTIF',
	'POWER',
	'PRODUCT',
	'ROUND',
	'INT',
	'SQRT',
]

export const areOnlyOneParameter: FormulaType[] = ['SQRT', 'INT', 'ROUND']
export const formulasWithLabels = [
	{
		_id: TableTypes.FormulaSliceType.VLOOKUP,
		label: TableTypes.FormulaSliceType.VLOOKUP,
	},
	...formulas.map((formulaKey) => ({
		_id: formulaKey,
		label: formulaKey,
	})),
]

const INVISIBLE_OPERATORS: (keyof typeof TableTypes.Operator)[] = [
	// this operators are needed for formula operations but need to be hidden for users
	'COMMA',
	'OPEN_SQUARE_BRACKET',
	'CLOSE_SQUARE_BRACKET',
]

export const arithmeticOperators = [
	...Object.keys(TableTypes.Operator)
		.filter(
			(operatorKey) =>
				!INVISIBLE_OPERATORS.includes(operatorKey as keyof typeof TableTypes.Operator),
		)
		.map((key) => ({
			_id: key,
			label: TableTypes.Operator[key as keyof typeof TableTypes.Operator],
		})),
]

type SpecialOperators = {
	type: TableTypes.FormulaSliceType.OPERATOR
	value: keyof typeof TableTypes.Operator
}

export const openBracket: SpecialOperators = {
	type: TableTypes.FormulaSliceType.OPERATOR,
	value: 'OPEN_BRACKET',
}

export const openSquareBracket: SpecialOperators = {
	type: TableTypes.FormulaSliceType.OPERATOR,
	value: 'OPEN_SQUARE_BRACKET',
}

export const closeBracket: SpecialOperators = {
	type: TableTypes.FormulaSliceType.OPERATOR,
	value: 'CLOSE_BRACKET',
}

export const closeSquareBracket: SpecialOperators = {
	type: TableTypes.FormulaSliceType.OPERATOR,
	value: 'CLOSE_SQUARE_BRACKET',
}

export const conditionOptions = [
	{
		_id: '>',
		label: 'Greater than',
	},
	{
		_id: '>=',
		label: 'Greater than or equal to',
	},
	{
		_id: '<',
		label: 'Less than',
	},
	{
		_id: '<=',
		label: 'Less than or equal to',
	},
	{
		_id: '=',
		label: 'Equal to',
	},
]

export const allOperators = [
	...formulasWithLabels,
	...arithmeticOperators,
	...conditionOptions,
	...INVISIBLE_OPERATORS.map((invisibleKeyOperator) => {
		return {
			_id: invisibleKeyOperator,
			label: TableTypes.Operator[invisibleKeyOperator as keyof typeof TableTypes.Operator],
		}
	}),
]

export const getNumberColumns = (fields: TableTypes.Field[]) => {
	return fields.filter((field) => {
		const fieldType = getFieldType(field.type)
		return fieldType === 'number'
	})
}
