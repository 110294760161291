import { TableTypes } from '@cango-app/types'
import { GridColDef } from '@mui/x-data-grid-premium'

export const isSingleSelectColumn = (
	column: GridColDef,
): column is GridColDef & {
	valueOptions: TableTypes.Field['valueOptions'] // redefine it as GridSingleSelectColDef does not quite work like expected for this case
} => {
	return ['singleSelect', 'custom'].includes(column.type as string)
}
