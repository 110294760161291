import { TableTypes } from '@cango-app/types'
import MenuItem from '@mui/material/MenuItem'
import {
	GridColumnMenu,
	GridColumnMenuItemProps,
	GridColumnMenuProps,
} from '@mui/x-data-grid-premium'
import { ComponentType, useContext, useMemo, useState } from 'react'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { PulseLoader } from 'react-spinners'
import _orderBy from 'lodash/orderBy'
import _uniq from 'lodash/uniq'
import { v4 } from 'uuid'

import { Box, Button, Select, TextField } from 'src/components'
import { TableContext } from 'src/providers/table-provider'
import { FlagIcon, TrashIcon } from 'src/assets/icons'

import { columnsWithSettings } from './column-settings/types'

const fieldTypeOptions = _orderBy(
	[
		{ label: 'Text', _id: TableTypes.FieldType.STRING },
		{ label: 'Number', _id: TableTypes.FieldType.NUMBER },
		{ label: 'Date', _id: TableTypes.FieldType.DATE },
		{ label: 'True / False', _id: TableTypes.FieldType.BOOLEAN },
		{ label: 'Single Select', _id: TableTypes.FieldType.SINGLE_SELECT },
		// { label: 'Table Select', _id: TableTypes.FieldType.TABLE_SELECT },
		{ label: 'Contact', _id: TableTypes.FieldType.CONTACT },
		{ label: 'Formula', _id: TableTypes.FieldType.CALCULATION },
		{
			label: 'Resources',
			_id: TableTypes.FieldType.RESOURCES,
		},
		{
			label: 'URL',
			_id: TableTypes.FieldType.URL,
		},
		{
			label: 'Video',
			_id: TableTypes.FieldType.VIDEO,
		},
		{
			label: 'Reference',
			_id: TableTypes.FieldType.REFERENCE,
		},
		{
			label: 'Concatination',
			_id: TableTypes.FieldType.CONCATINATION,
		},
	],
	'label',
)

const ColumnNameField: ComponentType<GridColumnMenuItemProps & { name: string }> = (props) => {
	const { onUpdateColumn, isUpdatingTable } = useContext(TableContext)
	const [columnName, setColumnName] = useState(props.name)

	return (
		<Box m={1}>
			<TextField
				label="Column name"
				value={columnName}
				onChange={(event) => setColumnName(event.target.value)}
				onKeyDown={(e) => e.stopPropagation()}
				disabled={isUpdatingTable}
				size="small"
				fullWidth
			/>
			{props.name !== columnName && !!columnName && (
				<Button
					variant="text"
					onClick={() => onUpdateColumn(props.colDef.field, { name: columnName })}
					size="small"
					fullWidth
					isLoading={isUpdatingTable}
					sx={{ minWidth: 50, mt: 0.5 }}
				>
					Save
				</Button>
			)}
		</Box>
	)
}

const FieldTypeSelector: ComponentType<
	GridColumnMenuItemProps & {
		column: TableTypes.Field
		openSettings: (fieldId: string) => void
	}
> = (props) => {
	const {
		onUpdateColumn,
		isUpdatingTable,
		tableList,
		apiRef,
		mappedColumns,
		table,
		cacheMultipleRowUpdates,
		saveChanges,
	} = useContext(TableContext)
	const column = mappedColumns.get(props.colDef.field)

	const handleUpdateColumnType = async (type: TableTypes.FieldType) => {
		if (apiRef?.current) {
			// remove filters when updating column
			apiRef.current.setFilterModel({ items: [] })
		}
		const previousType = column?.type
		if (
			type === TableTypes.FieldType.SINGLE_SELECT &&
			previousType === TableTypes.FieldType.STRING
		) {
			const allColumnValues =
				table?.records.reduce((_acc: string[], _record) => {
					if (_record.data[props.colDef.field]) {
						_acc = _uniq([..._acc, String(_record.data[props.colDef.field])])
					}
					return _acc
				}, []) ?? []
			const colValuesWithIds = allColumnValues.map((value) => ({ _id: v4(), label: value }))
			const recordsWithAssignedIndex: { _id: string; valueIndex: number }[] =
				table?.records.map((_record) => {
					const indexOfValue = allColumnValues.indexOf(String(_record.data[props.colDef.field]))
					return {
						_id: _record._id,
						valueIndex: indexOfValue,
					}
				}) ?? []
			const allRows =
				table?.records.map((_record) => {
					return apiRef.current.getRow(_record._id)
				}) ?? []
			const updatedRows: Map<string, any> = new Map(
				allRows.map((_row) => {
					if (!_row[props.colDef.field]) {
						return [_row._id, _row]
					}
					const recordWithIndex = recordsWithAssignedIndex.find(
						(_record) => _record._id === _row._id,
					)
					if (!recordWithIndex) {
						return [_row._id, _row]
					}
					return [
						_row._id,
						{
							..._row,
							[props.colDef.field]: colValuesWithIds[recordWithIndex.valueIndex]._id,
						},
					]
				}),
			)

			cacheMultipleRowUpdates(
				allRows.map((_row) => ({
					oldRow: _row,
					newRow: updatedRows.get(_row._id),
				})),
				true,
			)

			await onUpdateColumn(props.colDef.field, {
				type,
				valueOptions: colValuesWithIds,
			})

			await saveChanges()

			return
		}
		await onUpdateColumn(props.colDef.field, { type })
	}

	return (
		<>
			<Box m={1}>
				<Select
					onChange={(event) => handleUpdateColumnType(event.target.value as TableTypes.FieldType)}
					label="Column type"
					options={fieldTypeOptions}
					disabled={isUpdatingTable}
					value={props.column.type}
					size="small"
					containerProps={{ width: '100%' }}
					fullWidth
				/>
				{[TableTypes.FieldType.NUMBER, TableTypes.FieldType.CALCULATION].includes(
					props.column.type,
				) && (
					<Select
						label="Format"
						options={Object.values(TableTypes.FormatType).map((format) => ({
							_id: format,
							label: format,
						}))}
						value={props.column.format}
						onChange={(event) => {
							onUpdateColumn(props.colDef.field, {
								format: event.target.value as TableTypes.FormatType,
							})
						}}
					/>
				)}
				{props.column.type === TableTypes.FieldType.REFERENCE && (
					<Select
						label="Table"
						options={tableList}
						value={props.column.reference}
						onChange={(event) => {
							onUpdateColumn(props.colDef.field, {
								reference: event.target.value as string,
							})
						}}
					/>
				)}
				{columnsWithSettings.includes(props.column.type) && (
					<Button variant="text" fullWidth onClick={() => props.openSettings(props.column._id)}>
						Settings
					</Button>
				)}
			</Box>
		</>
	)
}

const DeleteColumnMenuItem: ComponentType<GridColumnMenuItemProps> = (props) => {
	const { onDeleteColumn } = useContext(TableContext)
	const [isDeleting, setIsDeleting] = useState(false)

	const handleDeleteColumn = async () => {
		const confirmed = confirm('Are you sure you want to delete this column?')
		if (confirmed) {
			setIsDeleting(true)
			await onDeleteColumn(props.colDef.field)
			setIsDeleting(false)
		}
	}

	return (
		<MenuItem onClick={handleDeleteColumn}>
			<ListItemIcon>
				<TrashIcon width={16} />
			</ListItemIcon>
			{isDeleting ? <PulseLoader size={4} /> : <ListItemText>Delete column</ListItemText>}
		</MenuItem>
	)
}

const PrincipalColumnSelectMenuItem: ComponentType<GridColumnMenuItemProps> = (props) => {
	const { updateTableConfig } = useContext(TableContext)

	if (props.isPrincipalColumn) {
		return null
	}

	return (
		<MenuItem
			onClick={() => updateTableConfig({ principal_field: props.colDef.field })}
			sx={{ mt: 1 }}
		>
			<ListItemIcon>
				<FlagIcon width={16} />
			</ListItemIcon>
			<ListItemText>Make principal column</ListItemText>
		</MenuItem>
	)
}

const slots = {
	columnMenuPinningItem: null,
	columnMenuColumnsItem: null,
	columnMenuFieldSelector: FieldTypeSelector,
	columnMenuNameItem: ColumnNameField,
	columnMenuDeleteColumnItem: DeleteColumnMenuItem,
	columnPrincipalColumnSelect: PrincipalColumnSelectMenuItem,
}

export const CustomColumnMenu: ComponentType<GridColumnMenuProps> = (props) => {
	const { table, mappedColumns } = useContext(TableContext)

	const column = useMemo(
		() => mappedColumns.get(props.colDef.field),
		[mappedColumns, props.colDef.field],
	)

	if (!column) {
		return <GridColumnMenu {...props} />
	}

	return (
		<>
			<GridColumnMenu
				{...props}
				slots={slots}
				slotProps={{
					columnMenuFieldSelector: {
						displayOrder: 15,
						column: column,
						openSettings: props.slotProps?.openSettings,
					},
					columnMenuNameItem: {
						name: column.name,
						displayOrder: 14,
					},
					columnMenuDeleteColumnItem: {
						displayOrder: 21,
					},
					columnPrincipalColumnSelect: {
						displayOrder: 16,
						isPrincipalColumn: props.colDef.field === table?.principal_field,
					},
				}}
			/>
		</>
	)
}
