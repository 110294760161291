import { GridAggregationModel, GridValidRowModel } from '@mui/x-data-grid-premium'
import _isEmpty from 'lodash/isEmpty'
import { TableTypes } from '@cango-app/types'

import { cangoTableAggregations } from '../../modules/tables/core-table'

export const processUpdatedAggregatedRow = ({
	groupId,
	groupName,
	subRows,
	aggregations,
	groupColumn,
	mappedColumns,
}: {
	groupId: string
	groupName: string
	subRows: GridValidRowModel[]
	aggregations: GridAggregationModel
	groupColumn: string
	mappedColumns: Map<string, TableTypes.Field>
}): GridValidRowModel => {
	const groupedAggregations = subRows.reduce((_aggs: { [fieldId: string]: any[] }, _row) => {
		Object.keys(_row).forEach((_rowFieldId) => {
			if (_aggs[_rowFieldId]) {
				_aggs[_rowFieldId].push(_row[_rowFieldId])
			} else {
				_aggs[_rowFieldId] = [_row[_rowFieldId]]
			}
		})
		return _aggs
	}, {})

	return {
		_id: groupId,
		[groupColumn]: groupName,
		...(!_isEmpty(aggregations)
			? [...mappedColumns.keys()].reduce((acc: { [fieldId: string]: any }, fieldId) => {
					const aggregationType = aggregations[fieldId]
					const values = groupedAggregations[fieldId] ?? []
					if (aggregationType === undefined || !cangoTableAggregations[aggregationType]) {
						const columnType = mappedColumns.get(fieldId)?.type
						if (columnType === TableTypes.FieldType.BOOLEAN) {
							return {
								...acc,
								[fieldId]: values.some((value) => value),
							}
						}
						return acc
					}
					const field = cangoTableAggregations[aggregationType].apply({
						values,
						field: fieldId,
						groupId,
					})
					return {
						...acc,
						[fieldId]: field,
					}
				}, {})
			: {}),
	}
}
